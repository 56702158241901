import React from 'react'
import { Provider } from 'react-redux'
import { createTheme, responsiveFontSizes, ThemeProvider } from '@mui/material'
import { defaultLocale, themeOptions } from '@trr/mui-theme'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFnsV3'
import { sv } from 'date-fns/locale'
import { LocalizationProvider } from '@mui/x-date-pickers'
import { AppShellDataProvider, AppShellDataProps } from '@trr/app-shell-data'
import { GTMTracker } from '@trr/gtm-tracking'

import Yrken from '../features/Yrke/Yrken'
import MainContainer from '../components/MainContainer/MainContainer'
import Kompetenser from '../features/Kompetenser/Kompetenser'
import Utbildningar from '../features/Utbildning/Utbildningar'
import Utbildning from '../features/Utbildning/Utbildning'
import Yrke from '../features/Yrke/Yrke'
import Rapporter from '../features/Rapporter/Rapporter'
import Rapport from '../features/Rapporter/Rapport'
import { store } from 'src/store'
import { initTranslations } from 'src/utils/initTranslations'
import { AppShellRouter, KeyRoute } from 'src/utils/router'

const App = ({ appShellData }: { appShellData: AppShellDataProps }) => {
  let theme = createTheme(themeOptions, defaultLocale)
  theme = responsiveFontSizes(theme)

  // Init our dictionary of strings
  initTranslations()

  return (
    <ThemeProvider theme={theme}>
      <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={sv}>
        <Provider store={store}>
          <GTMTracker mfName="prognosportalen">
            <AppShellDataProvider value={appShellData}>
              <MainContainer>
                <AppShellRouter currentKey={appShellData.currentKey}>
                  <KeyRoute urlKey={'utbildningar'}>
                    <Utbildningar />
                  </KeyRoute>
                  <KeyRoute urlKey={'utbildning'}>
                    <Utbildning />
                  </KeyRoute>
                  <KeyRoute urlKey={'kompetenser'}>
                    <Kompetenser />
                  </KeyRoute>
                  <KeyRoute urlKey={'yrken'}>
                    <Yrken />
                  </KeyRoute>
                  <KeyRoute urlKey={'yrke'}>
                    <Yrke />
                  </KeyRoute>
                  <KeyRoute urlKey={'rapporter'}>
                    <Rapporter />
                  </KeyRoute>
                  <KeyRoute urlKey={'rapport'}>
                    <Rapport />
                  </KeyRoute>
                </AppShellRouter>
              </MainContainer>
            </AppShellDataProvider>
          </GTMTracker>
        </Provider>
      </LocalizationProvider>
    </ThemeProvider>
  )
}

export default App
