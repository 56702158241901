import {
  Button,
  Chip,
  Divider,
  List,
  ListItem,
  Stack,
  Table,
  TableCell,
  TableRow,
  Typography,
} from '@mui/material'
import React from 'react'
import { RouteComponentProps } from 'react-router-dom'
import { format } from 'date-fns'
import { ArrowBack, DownloadForOffline } from '@mui/icons-material'
// eslint-disable-next-line import/no-extraneous-dependencies
import { useEpiContent } from '@trr/app-shell-data'

import { useGetIndustryReportQuery } from '../../utils/Network/industryReportApi'
import downloadBlobHelper from '../../utils/Helpers/downloadBlobHelper'
import PageWrapper from '../../Components/PageWrapper/PageWrapper'
import { Content } from '../../App/App.types'

interface IMatchParams {
  id: string
}
interface IRapportProps extends RouteComponentProps<IMatchParams> {
  id: string
}

const Rapport: React.FC<IRapportProps> = ({ match }) => {
  const { data, isLoading, isError } = useGetIndustryReportQuery(
    match.params.id
  )
  const {
    rapport: {
      errorFetchReport,
      typ,
      publiceringsdatum,
      utgivare,
      regioner,
      nationell,
      laddaNer,
      tillbaka,
    },
  } = useEpiContent<Content>()
  const handleClickDownload = async () => {
    if (data?.id)
      await downloadBlobHelper(data?.id, data?.fileName ?? data.title)
  }
  return (
    <>
      <PageWrapper
        isLoading={isLoading}
        errorText={errorFetchReport}
        errorLoadingData={isError}
      >
        <Typography
          variant="h1"
          marginBottom={3}
          sx={{ wordBreak: 'break-word', overflowWrap: 'break-word' }}
        >
          {data?.title}
        </Typography>
        <Typography variant="preamble" component="p" marginBottom={3}>
          {data?.description}
        </Typography>

        <Table sx={{ mb: 3 }}>
          <TableRow>
            <TableCell
              component="th"
              scope="row"
              sx={{
                paddingY: 2,
                paddingX: 1,
                width: '200px',
                verticalAlign: 'top',
              }}
            >
              <Typography variant="body1">{typ}</Typography>
            </TableCell>
            <TableCell sx={{ paddingY: 2, paddingX: 1, verticalAlign: 'top' }}>
              <Typography variant="body1">Rapport</Typography>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell
              component="th"
              scope="row"
              sx={{
                paddingY: 2,
                paddingX: 1,
                width: '200px',
                verticalAlign: 'top',
              }}
            >
              <Typography variant="body1">{publiceringsdatum}</Typography>
            </TableCell>
            <TableCell sx={{ paddingY: 2, paddingX: 1, verticalAlign: 'top' }}>
              <Typography variant="body1">
                {data?.publicationDate
                  ? format(new Date(data.publicationDate), 'yyyy-MM-dd')
                  : ''}
              </Typography>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell
              component="th"
              scope="row"
              sx={{
                paddingY: 2,
                paddingX: 1,
                width: '200px',
                verticalAlign: 'top',
              }}
            >
              <Typography variant="body1">{utgivare}</Typography>
            </TableCell>
            <TableCell sx={{ paddingY: 2, paddingX: 1, verticalAlign: 'top' }}>
              <Typography variant="body1">{data?.publisher}</Typography>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell
              component="th"
              scope="row"
              sx={{
                paddingY: 2,
                paddingX: 1,
                width: '200px',
                verticalAlign: 'top',
              }}
            >
              <Typography variant="body1">{regioner}</Typography>
            </TableCell>
            <TableCell
              sx={{ paddingY: '4px', paddingX: 1, verticalAlign: 'top' }}
            >
              <Stack
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  gap: '8px',
                  flexWrap: 'wrap',
                }}
              >
                <List
                  sx={{
                    display: 'flex',
                    flexFlow: 'row wrap',
                    paddingBottom: 0,
                  }}
                >
                  {data?.allRegions ? (
                    <ListItem
                      sx={{
                        width: 'auto',
                        border: 'none',
                        padding: '0',
                        paddingRight: 1,
                        paddingBottom: 1,
                        display: 'inline',
                      }}
                    >
                      {' '}
                      <Chip variant="outlined" label={nationell} />
                    </ListItem>
                  ) : (
                    data?.regions?.map((region) => {
                      if (!region) return null
                      return (
                        <ListItem
                          key={region.id}
                          sx={{
                            width: 'auto',
                            border: 'none',
                            padding: '0',
                            paddingRight: 1,
                            paddingBottom: 1,
                            display: 'inline',
                          }}
                        >
                          <Chip variant="outlined" label={region.name} />
                        </ListItem>
                      )
                    })
                  )}
                </List>
              </Stack>
            </TableCell>
          </TableRow>
        </Table>

        <Button
          startIcon={<DownloadForOffline />}
          variant="contained"
          size="medium"
          onClick={() => void handleClickDownload()}
          sx={{ mb: 3 }}
        >
          {laddaNer}
        </Button>
        <Divider sx={{ mt: 4, mb: 3 }} />
        <Button
          sx={{ maxWidth: 'fit-content', ml: -3 }}
          startIcon={<ArrowBack />}
          variant="text"
          href="/rapporter"
        >
          {tillbaka}
        </Button>
      </PageWrapper>
    </>
  )
}

export default Rapport
