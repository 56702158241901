import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
//import { createSlice } from '@reduxjs/toolkit'

import getConfig from '../Config/configService'
import { IMunicipality } from '../../types/IMunicipality.types'

import { prepareHeaders } from './prepareHeaders'
import { County } from 'src/types/County'
import { Location, LocationType } from 'src/types/Location'

const { API_URL } = getConfig()

export const locationsApi = createApi({
  reducerPath: 'locations-api',
  baseQuery: fetchBaseQuery({
    baseUrl: `${API_URL}referencedata/locations`,
    prepareHeaders,
  }),
  tagTypes: ['Locations'],
  endpoints: (build) => ({
    getMunicipalities: build.query<Location[], void>({
      query: () => ({ url: `municipalities` }),
      transformResponse: (response: IMunicipality[]): Location[] =>
        response.map((el) => ({
          id: el.countyAndMunicipalityCode,
          name: el.municipality,
          type: LocationType.municipality,
        })),
    }),
    getCounties: build.query<Location[], void>({
      query: () => ({ url: `counties` }),
      transformResponse: (response: County[]): Location[] =>
        response.map((el) => ({
          id: el.countyCode,
          name: el.county,
          type: LocationType.county,
        })),
    }),
  }),
})

export const { useGetMunicipalitiesQuery, useGetCountiesQuery } = locationsApi
