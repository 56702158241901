import { ArrowDropDown, ArrowDropUp } from '@mui/icons-material'
import {
  Chip,
  Select,
  MenuItem,
  SelectChangeEvent,
  useTheme,
} from '@mui/material'
import React, { useRef } from 'react'

export interface MenuItem {
  name: string
  value: string
}

interface ChipSelect {
  value: string | null
  label: string
  open: boolean
  menuItems?: MenuItem[] | undefined
  onChange: (event: SelectChangeEvent) => void
  onClick: () => void
}

const ChipSelect = ({
  value,
  label,
  open,
  menuItems,
  onChange,
  onClick,
}: ChipSelect) => {
  const { palette } = useTheme()
  const anchorRef = useRef(null)

  return (
    <>
      <Chip
        data-testid="chip-select"
        ref={anchorRef}
        variant="outlined"
        label={
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <span>{label}</span>
            {open ? (
              <ArrowDropUp
                onClick={onClick}
                style={{ color: palette.grey[700] }}
              />
            ) : (
              <ArrowDropDown
                onClick={onClick}
                style={{ color: palette.grey[700] }}
              />
            )}
          </div>
        }
        onClick={onClick}
        sx={{ backgroundColor: palette.background.paper }}
      />
      <Select
        open={open}
        value={value ?? ''}
        onChange={onChange}
        onClick={onClick}
        MenuProps={{
          anchorEl: anchorRef.current,
          style: {
            marginTop: '8px',
          },
        }}
        sx={{
          position: 'absolute',
          visibility: 'hidden',
        }}
      >
        {menuItems?.map((menuItem) => (
          <MenuItem
            data-testid="chip-select-item"
            key={menuItem.value}
            value={menuItem.value}
          >
            {menuItem.name}
          </MenuItem>
        ))}
      </Select>
    </>
  )
}

export default ChipSelect
