export const buildUrl = (subroutes: string[], endIndex?: number): string => {
  let url = ''
  const last =
    endIndex !== undefined
      ? Math.min(endIndex, subroutes.length)
      : subroutes.length
  for (let i = 0; i < last; i++) {
    const subroute = subroutes[i]
    if (subroute.startsWith('/')) {
      url += subroute
    } else {
      url += `/${subroute}`
    }
  }
  return url
}
