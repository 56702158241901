import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { createSlice } from '@reduxjs/toolkit'

import getConfig from '../Config/configService'
import { IFilter } from '../../types/IFilter.types'

import { prepareHeaders } from './prepareHeaders'

const { API_URL } = getConfig()

export const educationParametersApi = createApi({
  reducerPath: 'educationParameters-api',
  baseQuery: fetchBaseQuery({
    baseUrl: `${API_URL}kompetens/prognosportal/parameters/`,
    prepareHeaders,
  }),
  tagTypes: ['EducationForms', 'EducationTypes'],
  endpoints: (build) => ({
    getEducationForms: build.query<IFilter[], void>({
      query: () => ({ url: `educationforms` }),
    }),
    getEducationTypes: build.query<IFilter[], void>({
      query: () => ({ url: `educationtypes` }),
    }),
  }),
})

export const educationParametersSlice = createSlice({
  name: 'educationParameters',
  initialState: {
    educationForms: [] as unknown as IFilter[],
    educationTypes: [] as unknown as IFilter[],
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addMatcher(
      educationParametersApi.endpoints.getEducationForms.matchFulfilled,
      (_state, action: { payload: IFilter[] }) => {
        return {
          ..._state,
          educationForms: action.payload,
        }
      }
    ),
      builder.addMatcher(
        educationParametersApi.endpoints.getEducationTypes.matchFulfilled,
        (_state, action: { payload: IFilter[] }) => {
          return {
            ..._state,
            educationTypes: action.payload,
          }
        }
      )
  },
})

export const { useGetEducationFormsQuery, useGetEducationTypesQuery } =
  educationParametersApi
