/* eslint-disable import/no-extraneous-dependencies */
import { Pagination, Stack, Box, Collapse, Typography } from '@mui/material'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { TransitionGroup } from 'react-transition-group'
import { isEmpty } from 'ramda'
import { HiddenText } from 'src/Components/HiddenText/HiddenText'
import { Content } from 'src/App/App.types'
import { useEpiContent } from '@trr/app-shell-data'

import { IApplicationState } from '../../../../utils/Store/configureStore'
import { updatePage } from '../../../../utils/Store/educationFilterSlice'
import EducationCard from '../EducationCard/EducationCard'
import { IEducation } from '../../types/IEducation.types'

interface IEducationsListProps {
  educations: IEducation[]
  header?: string
  noResult?: React.ReactNode | null
  showTypeToggle: boolean
  itemHeaderComponent: string
}

const EducationsList: React.FC<IEducationsListProps> = ({
  educations,
  header,
  noResult,
  showTypeToggle,
  itemHeaderComponent,
}) => {
  const dispatch = useDispatch()
  const page = useSelector(
    (state: IApplicationState) => state.educationFilter.page
  )
  const {
    utbildningar: { hits },
  } = useEpiContent<Content>()
  if (!showTypeToggle && educations.length < 1) return <>{noResult}</>
  return (
    <Box sx={{ mt: 3 }}>
      {header && (
        <Typography variant="h6" component="h2">
          {header}
        </Typography>
      )}
      {isEmpty(educations) ? (
        <>{noResult}</>
      ) : (
        <>
          {!header && (
            <HiddenText>
              {educations.length} {hits}
            </HiddenText>
          )}
          <TransitionGroup>
            {educations.length > 0 &&
              educations?.slice(page * 10, page * 10 + 10)?.map((r) => {
                if (r.id)
                  return (
                    <Collapse key={r.id}>
                      <EducationCard
                        id={r.id}
                        headerComponent={itemHeaderComponent}
                        education={r}
                      />
                    </Collapse>
                  )
              })}
          </TransitionGroup>
          {educations.length < 1 && <>{noResult}</>}
          {educations?.length > 10 && (
            <Stack
              sx={{ mt: 3 }}
              justifyContent="center"
              direction="row"
              spacing={2}
            >
              <Pagination
                page={page + 1}
                onChange={(e, v) => {
                  dispatch(updatePage(v - 1))
                  window.scrollTo(0, 100)
                }}
                count={Math.ceil(educations.length / 10)}
              />
            </Stack>
          )}
        </>
      )}
    </Box>
  )
}

export default EducationsList
