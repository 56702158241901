/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, Fragment } from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';

interface IScrollToTopProps extends RouteComponentProps<any> {
    children: JSX.Element | JSX.Element[]
  }

const ScrollToTop:React.FC<IScrollToTopProps> = ({ history, children})  =>{
  useEffect(() => {
    const unlisten = history.listen(() => {
      window.scrollTo(0, 150);
    });
    return () => {
      unlisten();
    }
  }, []);

  return <Fragment>{children}</Fragment>;
} 

export default withRouter(ScrollToTop);