/* eslint-disable @typescript-eslint/restrict-template-expressions */
/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { RefObject, useEffect, useRef, useState } from 'react'
import {
  Box,
  FormControlLabel,
  List,
  ListItem,
  Stack,
  Switch,
  Typography,
} from '@mui/material'
import { LocationOn, Person, WatchLater, Event } from '@mui/icons-material'
// eslint-disable-next-line import/no-extraneous-dependencies
import { useEpiContent, useIsFeatureEnabled } from '@trr/app-shell-data'
import { useTrackCustomClick } from '@trr/gtm-tracking'
import { format } from 'date-fns'

import { Content } from '../../../../App/App.types'
import { capitalize } from '../../../../utils/Helpers/capitalize'
import { EducationLanguage } from '../../../../types/EducationLanguage'
import { RecommendedPriorKnowledge } from '../../../../types/RecommendedPriorKnowledge'
import { EducationLevel } from '../../../../types/EducationLevel'
import { TimeOfStudy } from '../../../../types/TimeOfStudy'
import { CreditTypes } from '../../../../types/CreditTypes'
import { IEducation } from '../../types/IEducation.types'
import { useGetMunicipalitiesQuery } from '../../../../utils/Network/locationsApi'
import { Location } from '../../../../types/Location'
import { IconText } from '../../../../Components/IconText/IconText'
import EducationDescription from '../EducationDescription/EducationDescription'
import EducationSummary from '../EducationDescription/EducationSummary'
import useIsInViewport from '../../../../utils/Hooks/useIsInViewport'
import { sv } from 'date-fns/locale'

interface IEducationDetails {
  isSummary?: boolean
  hideBorder?: boolean
  education: IEducation
  headerComponent: string
  subheader?: string
}

const EducationDetails: React.FC<IEducationDetails> = ({
  isSummary = true,
  education,
  headerComponent,
  subheader,
}) => {
  const formatEducationTextFeature = useIsFeatureEnabled(
    'Prognosportalen-frontend_formatUtbildningstexter_perm_240619'
  )
  const {
    utbildningskort: {
      formateradText,
      distans,
      niva,
      forkunskap,
      typ,
      poang,
      tid,
      takt,
      sprak,
    },
  } = useEpiContent<Content>()
  const trackCustomClick = useTrackCustomClick()
  const [showFormattedText, setShowFormattedText] = useState<boolean>(
    formatEducationTextFeature
  )
  useEffect(() => {
    if (!showFormattedText && formatEducationTextFeature)
      setShowFormattedText(true)
  }, [formatEducationTextFeature])

  const { isFetching: isFetchingMunicipalities, data = [] as Location[] } =
    useGetMunicipalitiesQuery()

  const ref = useRef<HTMLDivElement | null>(null)
  const isInView = useIsInViewport(ref as RefObject<HTMLElement>)
  const HeaderComponent = ({ ...props }) =>
    React.createElement(headerComponent, props)

  const subHeading = () => {
    if (subheader) return subheader

    return `${education?.credits?.credits ? education?.credits?.credits : ''} ${CreditTypes[education?.credits?.code] ? CreditTypes[education?.credits?.code] + ',' : ''} ${education?.provider ? education?.provider + ',' : ''} ${education?.educationForm ? capitalize(education?.educationForm) + ',' : ''} ${education?.educationType ? capitalize(education?.educationType) : ''}`
  }

  return (
    <>
      <Stack
        ref={ref}
        justifyContent="space-between"
        alignItems={'baseline'}
        direction={{ xs: 'column', sm: 'row' }}
      >
        <Typography
          gutterBottom
          variant="h5"
          component={HeaderComponent}
          sx={{ flexBasis: { sm: '800px' } }}
        >
          {education?.title} {education?.code ? `(${education?.code})` : ''}
        </Typography>
        {formatEducationTextFeature && !isSummary && (
          <FormControlLabel
            sx={{ flex: { sm: '0 0 232px' } }}
            control={
              <Switch
                color="primary"
                checked={showFormattedText}
                onChange={() => {
                  setShowFormattedText(!showFormattedText)

                  trackCustomClick(
                    `Visa ai-formaterad text: ${!showFormattedText ? 'på' : 'av'}`
                  )
                }}
                name={formateradText}
              />
            }
            label={formateradText}
          />
        )}
      </Stack>
      <Typography
        color="text.secondary"
        gutterBottom
        variant="subtitle2"
        component="p"
      >
        {subHeading()}
      </Typography>
      <Stack sx={{ mt: 2, mb: 2 }} direction={{ xs: 'column', sm: 'row' }}>
        {education?.paceOfStudyPercentage && (
          <IconText
            label={`${takt} ${education.paceOfStudyPercentage}%`}
            icon={<WatchLater color="primary" />}
          />
        )}
        {education?.distance ? (
          <IconText label={distans} icon={<LocationOn color="primary" />} />
        ) : (
          <IconText
            label={
              !isFetchingMunicipalities
                ? data.find((f) => f.id === education?.municipalityCodes[0])
                    ?.name ?? ''
                : ''
            }
            icon={<LocationOn color="primary" />}
          />
        )}

        <IconText
          label={
            education?.languageOfInstruction &&
            EducationLanguage[education.languageOfInstruction]
          }
          icon={<Person color="primary" />}
        />

        {education?.eventInformation?.startDate && (
          <IconText
            label={
              education?.eventInformation.startDate
                ? `${format(
                    new Date(education?.eventInformation.startDate),
                    'd MMM yyyy',
                    { locale: sv }
                  )} - ${format(
                    new Date(education?.eventInformation.endDate),
                    'd MMM yyyy',
                    { locale: sv }
                  )}`
                : ''
            }
            icon={<Event color="primary" />}
          />
        )}
      </Stack>
      <Box sx={{ minHeight: isSummary ? '70px' : '300px' }}>
        {!isSummary ? (
          <EducationDescription
            lastEdited={education?.lastEdited}
            id={education?.id}
            showFormattedText={showFormattedText && formatEducationTextFeature}
            text={education?.description}
          />
        ) : (
          <EducationSummary
            lastEdited={education?.lastEdited}
            id={education?.id}
            isInView={isInView}
            showFormattedText={showFormattedText && formatEducationTextFeature}
            text={education?.description}
          />
        )}
      </Box>
      <List
        sx={{
          columns: { sx: '1', sm: '2' },
          maxWidth: '500px',
          marginBottom: 3,
          marginTop: 3,
          paddingTop: 0,
          paddingBottom: 0,
        }}
      >
        <ListItem sx={{ border: 'none', padding: '0  16px 0 0' }}>
          <Typography
            variant="subtitle2"
            component="span"
            color="text.secondary"
          >
            {niva}:{' '}
            {education?.educationLevel
              ? EducationLevel[education.educationLevel]
              : '-'}
          </Typography>
        </ListItem>
        <ListItem sx={{ border: 'none', padding: '0  16px 0 0' }}>
          <Typography
            variant="subtitle2"
            component="span"
            color="text.secondary"
          >
            {forkunskap}:{' '}
            {education?.recommendedPriorKnowledge
              ? RecommendedPriorKnowledge[education.recommendedPriorKnowledge]
              : '-'}
          </Typography>
        </ListItem>
        <ListItem sx={{ border: 'none', padding: '0  16px 0 0' }}>
          <Typography
            variant="subtitle2"
            component="span"
            color="text.secondary"
          >
            {typ}: {capitalize(education?.educationForm)}
          </Typography>
        </ListItem>
        <ListItem sx={{ border: 'none', padding: '0  16px 0 0' }}>
          <Typography
            variant="subtitle2"
            component="span"
            color="text.secondary"
          >
            {poang}: {education?.credits?.credits ?? '-'}{' '}
            {education?.credits?.code && CreditTypes[education.credits.code]}
          </Typography>
        </ListItem>
        <ListItem sx={{ border: 'none', padding: '0  16px 0 0' }}>
          <Typography
            variant="subtitle2"
            component="span"
            color="text.secondary"
          >
            {tid}:{' '}
            {education?.timeOfStudy ? TimeOfStudy[education.timeOfStudy] : '-'}
          </Typography>
        </ListItem>
        <ListItem sx={{ border: 'none', padding: '0  16px 0 0' }}>
          <Typography
            variant="subtitle2"
            component="span"
            color="text.secondary"
          >
            {takt}:{' '}
            {education?.paceOfStudyPercentage
              ? `${education.paceOfStudyPercentage}%`
              : '-'}
          </Typography>
        </ListItem>
        <ListItem sx={{ border: 'none', padding: '0  16px 0 0' }}>
          <Typography
            variant="subtitle2"
            component="span"
            color="text.secondary"
          >
            {sprak}:{' '}
            {education?.languageOfInstruction
              ? EducationLanguage[education.languageOfInstruction]
              : '-'}
          </Typography>
        </ListItem>
      </List>
    </>
  )
}

export default EducationDetails
