import { Box, Stack, Typography, useTheme } from '@mui/material'
import React from 'react'
import type { ChartStatistics } from 'src/features/Yrke/types/OccupationStatistics.types'

interface ChartParams {
  data: ChartStatistics[]
  horisontal: boolean
}

const Chart: React.FC<ChartParams> = ({ data, horisontal }) => {
  const { palette } = useTheme()

  const ariaLabel = data
    .map((item) => `${item.label} ${item.percent}%`)
    .join('. ')

  const filteredDataLength = data.filter((stat) => stat.percent !== 0).length

  const getBorderRadius = (
    i: number,
    filteredDataLength: number,
    horizontal: boolean
  ) => {
    if (filteredDataLength === 1) {
      return '8px'
    } else if (i === 0) {
      return horizontal ? '8px 0 0 8px' : '8px 8px 0 0'
    } else if (i === filteredDataLength - 1) {
      return horizontal ? '0 8px 8px 0' : '0 0 8px 8px'
    } else {
      return '0'
    }
  }

  return (
    <Stack
      role="figure"
      aria-label={ariaLabel}
      direction={{ xs: 'row', md: horisontal ? 'column' : 'row' }}
      gap="8px  24px"
      flex={{ xs: '1', md: horisontal ? 'auto' : '1' }}
    >
      <Stack
        direction={{ xs: 'column', md: horisontal ? 'row' : 'column' }}
        flex={{ xs: '1', md: horisontal ? 'auto' : '1' }}
        height={{
          xs: '250px',
          md: horisontal ? '40px' : '340px',
          lg: horisontal ? '40px' : '100%',
        }}
        maxWidth={{ xs: '125px', md: horisontal ? '100%' : '64px' }}
        minWidth={{ md: horisontal ? '' : '50px' }}
        color={palette.common.white}
      >
        {data
          .filter((stat) => stat.percent !== 0)
          .map((a, i) => (
            <Box
              key={i}
              sx={{
                backgroundColor: a.color,
                paddingX: 1,
              }}
              height={{
                xs: `${a.percent}%`,
                md: horisontal ? '100%' : `${a.percent}%`,
              }}
              width={{
                xs: '100%',
                md: horisontal ? `${a.percent}%` : '100%',
              }}
              minHeight="15px"
              minWidth="35px"
              display="flex"
              alignItems="center"
              justifyContent={{
                xs: 'center',
                md: horisontal ? 'left' : 'center',
              }}
              borderRadius={{
                xs: getBorderRadius(i, filteredDataLength, false),
                md: getBorderRadius(i, filteredDataLength, horisontal),
              }}
            >
              <Typography variant="body2" fontWeight="bold">
                {a.percent}%
              </Typography>
            </Box>
          ))}
      </Stack>
      <Stack
        direction={{ xs: 'column', md: horisontal ? 'row' : 'column' }}
        alignItems={{ xs: 'start', md: horisontal ? 'center' : 'start' }}
        justifyContent={{ xs: 'center', md: horisontal ? 'start' : 'center' }}
        alignSelf="stretch"
        flexWrap="wrap"
        flex="1"
        gap={{ xs: horisontal ? '8px 24px' : '24px' }}
      >
        {data.map((a, i) => (
          <Stack key={i} direction="row" alignItems="center" gap="8px">
            <Box
              sx={{
                backgroundColor: a.color,
                width: '16px',
                height: '16px',
              }}
              flexShrink="0"
            />
            <Typography variant="body2">{a.label}</Typography>
          </Stack>
        ))}
      </Stack>
    </Stack>
  )
}
export default Chart
