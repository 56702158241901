import { Box, Stack, Typography } from '@mui/material'
import React from 'react'
import { blue } from '@mui/material/colors'
import { useEpiContent } from '@trr/app-shell-data'

import { Content } from '../../../../App/App.types'
import { ISalaryStatistics } from '../../../../types/ISalaryStatistics.types'

interface ISalaryBarChartProps {
  statistics: ISalaryStatistics
}

const SalaryBarChart: React.FC<ISalaryBarChartProps> = ({ statistics }) => {
  const {
    lonestaplar: { minLon, minTio, kr, medelLon, maxLon, maxTio },
  } = useEpiContent<Content>()
  return (
    <Stack
      role="figure"
      aria-labelledby="salary-barchart-caption"
      direction={'row'}
      sx={{
        height: '250px',
        mt: 3,
        display: {
          xs: 'none',
          md: 'flex',
        },
      }}
    >
      <Box
        sx={{
          backgroundColor: blue[700],
          alignItems: 'flex-end',
          display: 'flex',
          p: 1,
          mt: 'auto',
          mb: 0,
          color: 'white',
          height: '50%',
          width: '30%',
        }}
      >
        <Box>
          {minLon}
          <br />
          {minTio}
          <br />
          <Typography variant="h4">
            {statistics.percentile10.toLocaleString()}&nbsp;{kr}
          </Typography>
        </Box>
      </Box>
      <Box
        sx={{
          alignItems: 'flex-end',
          display: 'flex',
          p: 1,
          mt: 'auto',
          mb: 0,
          backgroundColor: blue[800],
          color: 'white',
          height: '75%',
          width: '80%',
        }}
      >
        <Box>
          {medelLon}
          <Typography variant="h4">
            {statistics.average.toLocaleString()}&nbsp;{kr}
          </Typography>
        </Box>
      </Box>
      <Box
        sx={{
          alignItems: 'flex-end',
          display: 'flex',
          p: 1,
          mt: 'auto',
          mb: 0,
          backgroundColor: blue[900],
          color: 'white',
          height: '100%',
          width: '30%',
        }}
      >
        <Box>
          {maxLon} <br />
          {maxTio} <br />
          <Typography variant="h4">
            {statistics.percentile90.toLocaleString()}&nbsp;{kr}
          </Typography>
        </Box>
      </Box>
    </Stack>
  )
}

export default SalaryBarChart
