import React, { useEffect } from 'react'
import { Typography } from '@mui/material'
// eslint-disable-next-line import/no-extraneous-dependencies
import { useEpiContent } from '@trr/app-shell-data'

import { Content } from '../../../../App/App.types'
import { usePostFormatTextMutation } from '../../../../utils/Network/formatTextApi'
import WysiwygEditor from '../../../../Components/WysiwygEditor'
import DelayedSkeleton from '../../../../Components/DelayedSkeleton/DelayedSkeleton'

interface IEducationDescriptionProps {
  text: string | undefined
  id: string | undefined
  lastEdited?: string
  showFormattedText: boolean
}

const EducationDescription: React.FC<IEducationDescriptionProps> = ({
  text,
  id,
  lastEdited,
  showFormattedText,
}) => {
  const [formatText, res] = usePostFormatTextMutation()
  const {
    utbildningskort: { noDesc },
    kallor: { openInNew },
  } = useEpiContent<Content>()

  useEffect(() => {
    if (!res.data && text && id && showFormattedText)
      formatText({
        id,
        text,
        lastUpdated: lastEdited ?? undefined,
      }).catch((e) => console.log(e))
  }, [res.data, text, formatText, id, lastEdited, showFormattedText])

  const description = showFormattedText ? res?.data?.text : text

  const linkRegex = new RegExp(/<a (.*?)>(.*?)<\/a>/g)
  const icon =
    '<svg xmlns="http://www.w3.org/2000/svg" role="img" aria-hidden="true" preserveAspectRatio="xMidYMin slice" width="14" height="14" viewBox="0 0 24 24" style="fill: rgb(98, 34, 195); margin-right:5px; position:relative; top:1px;"><path fill="inherit" fill-rule="nonzero" d="M13.88 2c.144 0 .262.116.263.26v1.573c0 .145-.117.262-.262.262H2.619c-.29 0-.524.235-.524.524v16.762c0 .29.235.524.524.524h16.762c.29 0 .524-.235.524-.524V10.973H22v10.932C22 23.062 21.061 24 19.905 24H2.095C.94 24 0 23.062 0 21.905V4.095C0 2.94.939 2 2.095 2zm9.043-2C23.52 0 24 .482 24 1.077v5.33c0 .283-.112.554-.31.756-.418.424-1.1.428-1.523.011l-1.332-1.333c-.106-.104-.276-.104-.381 0l-7.137 7.137c-.631.631-1.654.631-2.285 0-.63-.63-.63-1.653 0-2.284l7.136-7.136c.106-.105.106-.276 0-.381l-1.332-1.333c-.206-.203-.322-.48-.322-.768.001-.596.484-1.077 1.08-1.076z"></path></svg>'
  const formattedDescription = description?.replaceAll(
    linkRegex,
    // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
    `<a target="_blank" title="$2 ${openInNew}" $1>${icon}$2</a>`
  )

  if (res.isLoading && showFormattedText)
    return <DelayedSkeleton variant="rounded" width={'100%'} height={300} />
  if (!text && !res.isLoading)
    return <Typography variant="body1"> {noDesc}</Typography>
  return (
    <Typography gutterBottom variant="subtitle2" component="div">
      <WysiwygEditor htmlFormattedString={formattedDescription ?? ''} />
    </Typography>
  )
}

export default EducationDescription
