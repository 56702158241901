import { Button, Stack, useMediaQuery, useTheme } from '@mui/material'
import React from 'react'
import { useEpiContent } from '@trr/app-shell-data'
import { Content } from 'src/App/App.types'

import Filter from './Filter'
import { FilterType } from './Filter/Filter'
import MobileFilter from './MobileFilter'
import { FilterBarDrawer } from './MobileFilter/FilterBarDrawer'

const Filters = ({
  filters,
  handleSetFilters,
  handleResetFilters,
}: {
  filters: FilterType[]
  handleSetFilters: (filterId: string, value: string) => void
  handleResetFilters: (filterId?: string) => void
}) => {
  const {
    filtrering: { allFilters, clearAllFilters },
  } = useEpiContent<Content>()
  const theme = useTheme()
  const isDesktop = useMediaQuery(theme.breakpoints.up('md'))

  return isDesktop ? (
    <Stack
      direction={'row'}
      spacing={1}
      sx={{ marginTop: 2, paddingBottom: 4 }}
    >
      {filters.map((el) => (
        <Filter
          key={el.id}
          filter={el}
          handleSetFilters={handleSetFilters}
          handleResetFilters={handleResetFilters}
        />
      ))}
    </Stack>
  ) : (
    <>
      <FilterBarDrawer
        title={allFilters}
        activeFilterCount={filters.reduce(
          (acc, init) => acc + init.filters.length,
          0
        )}
      >
        <Button
          size="small"
          variant="text"
          sx={{ p: 2 }}
          onClick={() => handleResetFilters()}
          disabled={filters.every((el) => el.filters.length === 0)}
        >
          {clearAllFilters}
        </Button>
        {filters.map((el) => (
          <MobileFilter
            key={el.id}
            filter={el}
            handleSetFilters={handleSetFilters}
          />
        ))}
      </FilterBarDrawer>
    </>
  )
}

export default Filters
