import { alpha, Box, Stack, Typography, useTheme } from '@mui/material'
import React from 'react'
import SourceMetaText from '../../SourceMetaText/SourceMetaText'
import Chart from './Chart'
import type { OccupationEducationStatistics as OccupationEducationStatisticsType } from 'src/features/Yrke/types/OccupationStatistics.types'
import { useEpiContent } from '@trr/app-shell-data'
import convertToStatistics from 'src/utils/helpers/convertToStatistics'
import { useTranslation } from 'react-i18next'
import { Content } from 'src/App/App.types'

interface OccupationEducationStatisticsProps {
  occupationEducationStatistics: OccupationEducationStatisticsType
}

const OccupationEducationStatistics: React.FC<
  OccupationEducationStatisticsProps
> = ({ occupationEducationStatistics }) => {
  const {
    yrke: { educationHeader, educationPreamble },
  } = useEpiContent<Content>()
  const { palette } = useTheme()
  const { t: tKallor } = useTranslation('translation', {
    keyPrefix: 'kallor',
  })
  const { t: tScbStatistik } = useTranslation('translation', {
    keyPrefix: 'scbStatistik',
  })

  const borderColor = palette.neutral?.main
    ? alpha(palette.neutral?.main, 0.16)
    : ''

  const educationLabelsAndColors = [
    {
      label: tScbStatistik('educationLow'),
      color: palette.secondary.main,
      key: 'low',
    },
    {
      label: tScbStatistik('educationMedium'),
      color: palette.error.main,
      key: 'medium',
    },
    {
      label: tScbStatistik('educationHigh'),
      color: palette.accent?.dark ?? '',
      key: 'high',
    },
    {
      label: tScbStatistik('educationHigher'),
      color: palette.primary.main,
      key: 'higher',
    },
    {
      label: tScbStatistik('educationMissing'),
      color: '#3560FF',
      key: 'missing',
    },
  ]

  const educationChartData = occupationEducationStatistics
    ? convertToStatistics(
        occupationEducationStatistics,
        educationLabelsAndColors
      )
    : []

  if (!occupationEducationStatistics) return null

  return (
    <Box
      sx={{
        backgroundColor: palette.surface?.blue,
        p: { xs: 2, sm: 5 },
        mt: { xs: 0, sm: 3 },
        borderTop: { xs: `1px solid ${borderColor}`, sm: 'none' },
      }}
      borderRadius={{ xs: 0, sm: 2 }}
    >
      <Stack direction="column" gap="16px">
        <Stack>
          <Typography variant="h4" component="h3">
            {educationHeader}
          </Typography>
          <Typography variant="body1" component="p">
            {educationPreamble}
          </Typography>
        </Stack>
        <Chart data={educationChartData} horisontal />
      </Stack>
      <SourceMetaText
        text={`${tKallor('kallaSCB')} (${occupationEducationStatistics.year}).`}
      />
    </Box>
  )
}

export default OccupationEducationStatistics
