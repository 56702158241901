import { ArrowForward, Info } from '@mui/icons-material'
import {
  alpha,
  Button,
  Card,
  CardActions,
  CardContent,
  Link,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material'

import React from 'react'
import { IconHeader } from 'src/components/IconHeader/IconHeader'

interface IndexCardParams {
  header: string
  preamble: string
  section: string
  icon: JSX.Element
  link: string
  disabled: boolean
  background?: string
  missing: string
}

const IndexCard: React.FC<IndexCardParams> = ({
  header,
  preamble,
  link,
  icon,
  section,
  disabled,
  background,
  missing,
}) => {
  const { palette, breakpoints } = useTheme()
  const smallScreensUp = useMediaQuery(breakpoints.up('sm'))
  const borderColor = alpha(palette.neutral?.main ?? 'rgb()', 0.16)
  const scrollToSection = (sectionId: string) => {
    const section = document.getElementById(sectionId)
    section?.scrollIntoView({ behavior: 'smooth' })
  }

  return (
    <>
      {disabled ? (
        <Card
          data-testid="index-card"
          sx={{
            backgroundColor: palette.surface?.grey,
            border: 'none',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            height: '100%',
            mt: { xs: 3, sm: 0 },
          }}
        >
          <CardContent sx={{ p: { xs: 2, sm: 3 } }}>
            <Stack flexDirection="column" gap={2}>
              <IconHeader
                label={header}
                icon={icon}
                background={background}
                disabled={disabled}
              />
              <Typography variant="body1">{preamble}</Typography>
            </Stack>
          </CardContent>
          <CardActions
            sx={{
              padding: 3,
              borderTop: { xs: `1px solid ${borderColor}`, sm: 'none' },
            }}
          >
            <Info />
            <Typography variant="body1">{missing}</Typography>
          </CardActions>
        </Card>
      ) : (
        <Card
          data-testid="index-card"
          sx={{
            backgroundColor: palette.surface?.blue,
            border: 'none',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            height: '100%',
          }}
        >
          <CardContent sx={{ p: { xs: 2, sm: 3 } }}>
            <Stack flexDirection="column" gap={2}>
              <IconHeader label={header} icon={icon} background={background} />
              <Typography variant="body1">{preamble}</Typography>
            </Stack>
          </CardContent>
          {smallScreensUp && (
            <CardActions>
              <Button
                variant="text"
                size="medium"
                onClick={() => scrollToSection(section)}
                component={Link}
                startIcon={<ArrowForward />}
                sx={{ paddingY: 1, paddingX: 2 }}
              >
                {link}
              </Button>
            </CardActions>
          )}
        </Card>
      )}
    </>
  )
}
export default IndexCard
