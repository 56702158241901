import React from 'react'
import { Box, Stack, Typography, useTheme } from '@mui/material'
import { useEpiContent } from '@trr/app-shell-data'
import InfoRounded from '@mui/icons-material/InfoRounded'

import { Content } from '../../../../App/App.types'

const OutlookEmptyState: React.FC = () => {
  const { palette } = useTheme()
  const {
    yrke: { ingenBedomning, ingenBedomningBody },
  } = useEpiContent<Content>()

  return (
    <Box
      sx={{
        backgroundColor: palette.surface?.blue,
        p: { xs: 2, sm: 5 },
        flexGrow: 1,
      }}
    >
      <Stack
        direction="row"
        gap={2}
        role="figure"
        alignItems={'end'}
        alignContent={'space-evenly'}
      >
        <Box>
          <InfoRounded color="info" sx={{ width: '54px', height: '54px' }} />
        </Box>
        <Box>
          <Typography variant="h3" component="h2">
            {ingenBedomning}
          </Typography>
          <Typography variant="body1">{ingenBedomningBody}</Typography>
        </Box>
      </Stack>
    </Box>
  )
}

export default OutlookEmptyState
