import { createSlice } from '@reduxjs/toolkit'
import { fetchBaseQuery } from '@reduxjs/toolkit/dist/query'
import { createApi } from '@reduxjs/toolkit/dist/query/react'

import {
  IOccupationOutlook,
  IOccupationOutlookNew,
} from '../../Features/Yrke/types/IOccupationOutlook.types'
import { ISalaryStatistics } from '../../types/ISalaryStatistics.types'
import { County } from '../../types/County'
import getConfig from '../Config/configService'
import { Data } from '../../Features/Rapporter/Filters/Filter/Filter'

import { prepareHeaders } from './prepareHeaders'
import { IJobTitle } from 'src/types/IJobTitle.types'
import { getOutlookValue } from '../Helpers/getOutlookValue'

const { API_URL } = getConfig()

export const referenceDataApi = createApi({
  reducerPath: 'referenceData-api',
  baseQuery: fetchBaseQuery({
    baseUrl: `${API_URL}referencedata`,
    prepareHeaders,
  }),
  tagTypes: ['ReferenceData'],
  endpoints: (build) => ({
    getOccupationOutlooks: build.query<
      IOccupationOutlook | null,
      {
        ssyk: string
        occupationId: string
      }
    >({
      query: ({ ssyk, occupationId }) => ({
        url: `/occupationalbarometer`,
        params: {
          ssyk,
          occupationId,
          lan: '00',
        },
      }),
      transformResponse: (
        response: IOccupationOutlookNew[] | string[],
        _,
        { ssyk, occupationId }
      ): IOccupationOutlook | null => {
        // TODO: this is a hack, the backend should return a error code instead of an empty array, then we can skip this check and the | null above
        if (!response || response.length === 0) return null

        const occupationOutlook = response[0] as IOccupationOutlookNew

        return {
          id: occupationOutlook.id,
          occupationId,
          conceptId: occupationOutlook.conceptId,
          year: occupationOutlook.omgang.slice(0, 4) ?? '',
          ssyk,
          outlookLabel: occupationOutlook?.jobbmojligheter
            ? `${occupationOutlook?.jobbmojligheter?.charAt(0).toUpperCase() + occupationOutlook?.jobbmojligheter?.slice(1)} möjligheter`
            : '',
          outlookValue: getOutlookValue(occupationOutlook.jobbmojligheter),
          geography: 'Riket',
          ingress: occupationOutlook.textJobbmojligheter,
          prognos: occupationOutlook.prognos,
          textRekryteringssituation:
            occupationOutlook.textRekryteringssituation,
        }
      },
    }),
    getSalaryStatistics: build.query<ISalaryStatistics, string>({
      query: (ssyk) => ({
        url: `/salarystatistics?ssyk=${ssyk}`,
      }),
    }),
    getAllCounties: build.query<Data[], void>({
      query: () => `/locations/counties`,
      transformResponse: (response: County[]): Data[] => {
        return response.map((el) => ({
          id: el.countyCode.startsWith('0')
            ? el.countyCode.slice(1)
            : el.countyCode,
          label: el.county,
        }))
      },
    }),
  }),
})

export const referenceDataSlice = createSlice({
  name: 'referenceData',
  initialState: {
    occupationalOutlook: {} as { [key: string]: IOccupationOutlook },
    salaryStatistics: {} as { [key: string]: ISalaryStatistics },
    jobTitles: [] as unknown as IJobTitle[],
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addMatcher(
      referenceDataApi.endpoints.getSalaryStatistics.matchFulfilled,
      (_state, action: { payload: ISalaryStatistics }) => {
        return {
          ..._state,
          salaryStatistics: {
            ..._state.salaryStatistics,
            [action.payload?.ssyk]: action.payload,
          },
        }
      }
    )
    builder.addMatcher(
      referenceDataApi.endpoints.getOccupationOutlooks.matchFulfilled,
      (_state, action: { payload: IOccupationOutlook | null }) => {
        if (action.payload === null) return

        const outlook = action.payload

        return {
          ..._state,
          occupationalOutlook: {
            ..._state.occupationalOutlook,
            [outlook?.occupationId]: outlook,
          } as { [key: string]: IOccupationOutlook },
        }
      }
    )
  },
})

export const {
  useGetOccupationOutlooksQuery,
  useGetSalaryStatisticsQuery,
  useGetAllCountiesQuery,
} = referenceDataApi
