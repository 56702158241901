import { getIdToken } from '@trr/app-shell-data'
import { getConfig } from '../getConfig'

const { API_URL } = getConfig()

const downloadBlobHelper = async (id: string, fileName: string) => {
  const response = await fetch(
    `${API_URL}/kompetens/IndustryReport/download/${id}`,
    {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${getIdToken() ?? ''}`,
      },
    }
  )
  if (response.ok) {
    const blob = await response.blob()
    const anchor = document.createElement('a')
    document.body.appendChild(anchor)
    const objectUrl = window.URL.createObjectURL(blob)
    anchor.href = objectUrl
    anchor.download = fileName
    anchor.target = '_blank'
    anchor.click()
  } else {
    console.error(response)
  }
}

export default downloadBlobHelper
