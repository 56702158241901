import { Box, Stack, Typography } from '@mui/material'
import React from 'react'
import { SalaryStatistics } from 'src/types/SalaryStatistics.types'
import { useTranslation } from 'react-i18next'

interface SalaryBarChartProps {
  statistics: SalaryStatistics
}

const SalaryBarChart: React.FC<SalaryBarChartProps> = ({ statistics }) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'yrke',
  })

  return (
    <Stack
      role="figure"
      aria-labelledby="salary-barchart-caption"
      direction={{ xs: 'column-reverse', md: 'row' }}
      sx={{
        height: { xs: '390px', md: '250px' },
        mt: 3,
        display: 'flex',
      }}
    >
      <Box
        sx={{
          backgroundColor: '#476AEB',
          alignItems: 'flex-end',
          display: 'flex',
          p: { xs: 2, md: 1 },
          mt: 'auto',
          mb: 0,
          color: 'white',
          height: { xs: '110px', md: '50%' },
          width: { xs: '100%', md: '30%' },
          borderTopLeftRadius: { xs: '0px', md: '8px' },
          borderBottomLeftRadius: '8px',
          borderBottomRightRadius: { xs: '8px', md: '0px' },
        }}
      >
        <Box>
          <Typography variant="body1" fontWeight="bold">
            {t('minLon')}
          </Typography>
          <Typography variant="body2">{t('minTio')}</Typography>
          <Typography variant="h5" component="p" paddingTop={1}>
            {statistics?.percentile10?.toLocaleString()} {t('kr')}
          </Typography>
        </Box>
      </Box>
      <Box
        sx={{
          alignItems: 'flex-end',
          display: 'flex',
          p: { xs: 2, md: 1 },
          mt: 'auto',
          mb: 0,
          backgroundColor: '#264CD9',
          color: 'white',
          height: { xs: '130px', md: '75%' },
          width: { xs: '100%', md: '40%' },
          borderTopLeftRadius: { xs: '0px', md: '8px' },
        }}
      >
        <Box>
          <Typography variant="body1" fontWeight="bold">
            {t('medelLon')}
          </Typography>
          <Typography variant="h5" component="p" paddingTop={1}>
            {statistics?.average?.toLocaleString()} {t('kr')}
          </Typography>
        </Box>
      </Box>
      <Box
        sx={{
          alignItems: 'flex-end',
          display: 'flex',
          p: { xs: 2, md: 1 },
          mt: 'auto',
          mb: 0,
          backgroundColor: '#1437B8',
          color: 'white',
          height: { xs: '150px', md: '100%' },
          width: { xs: '100%', md: '30%' },
          borderTopLeftRadius: '8px',
          borderTopRightRadius: '8px',
          borderBottomRightRadius: { xs: '0px', md: '8px' },
        }}
      >
        <Box>
          <Typography variant="body1" fontWeight="bold">
            {t('maxLon')}
          </Typography>
          <Typography variant="body2">{t('maxTio')}</Typography>
          <Typography variant="h5" component="p" paddingTop={1}>
            {statistics?.percentile90?.toLocaleString()} {t('kr')}
          </Typography>
        </Box>
      </Box>
    </Stack>
  )
}

export default SalaryBarChart
