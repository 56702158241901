import kompetensApi from '../kompetensApi'
import { Filter } from 'src/types/Filter.types'

export const educationParametersApi = kompetensApi.injectEndpoints({
  endpoints: (build) => ({
    getEducationForms: build.query<Filter[], void>({
      query: () => ({
        url: `/prognosportal/parameters/educationforms`,
      }),
    }),
    getEducationTypes: build.query<Filter[], void>({
      query: () => ({
        url: `/prognosportal/parameters/educationtypes`,
      }),
    }),
  }),
})

export const { useGetEducationFormsQuery, useGetEducationTypesQuery } =
  educationParametersApi
