import { Button, Chip } from '@mui/material'
import * as R from 'ramda'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { useGetEducationTypesQuery } from 'src/api/educationParameters'
import { ApplicationState } from 'src/store'
import { Filter } from 'src/types/Filter.types'
import {
  deleteAllFilters,
  deleteFilter,
} from 'src/api/slices/educationFilterSlice'
import { useTranslation } from 'react-i18next'

const filterList = (mainList: Filter[], filterList: Filter[]): Filter[] => {
  const flatFilters = R.flatten(R.values(mainList)) as Filter[]

  // Flatten array
  const filterIds = R.pluck('id', filterList)
  const filterPredicate = R.complement(
    R.propSatisfies(R.includes(R.__, filterIds), 'id')
  )
  let filteredList = R.filter(filterPredicate, flatFilters)

  // Filter out empty objects
  const isNotEmptyObject = (obj: Filter) => !R.isEmpty(obj)
  filteredList = R.filter(isNotEmptyObject, filteredList)

  // Filter out undefined items
  const isDefined = (obj: Filter | undefined): obj is Filter =>
    obj !== undefined
  filteredList = R.filter(isDefined, filteredList)

  return filteredList
}

const EducationFilterChips = () => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'filtrering',
  })
  const dispatch = useDispatch()
  const filters = useSelector(
    (state: ApplicationState) => state.educationFilter.filters
  )
  const {
    data: educationTypes = [] as Filter[],
    isLoading: isloadingEducationTypeData,
  } = useGetEducationTypesQuery()

  // Convert filters (of type EducationFilters) to a flat array
  const filteredList = filterList(filters as Filter[], educationTypes)

  if (isloadingEducationTypeData) return null

  return (
    <>
      {filteredList.length > 0 && (
        <Button
          data-testid="clear-all-filters-button"
          onClick={() => dispatch(deleteAllFilters())}
          size="small"
        >
          {t('rensa')}
        </Button>
      )}
      {filteredList.map((f) => {
        if (f) {
          return (
            <Chip
              data-testid="education-filter-chip"
              key={f.id}
              color="primary"
              variant="outlined"
              onDelete={() => {
                dispatch(deleteFilter(f))
              }}
              label={f.name}
            />
          )
        }
      })}
    </>
  )
}

export default EducationFilterChips
