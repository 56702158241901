/* eslint-disable @typescript-eslint/prefer-nullish-coalescing */
/* eslint-disable @typescript-eslint/no-misused-promises */
import { FilterList } from '@mui/icons-material'
import { Box, Button, TextField } from '@mui/material'
import { useFormik } from 'formik'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useEpiContent } from '@trr/app-shell-data'

import { Content } from '../../App/App.types'
import InputHeader from '../../Components/InputHeader/InputHeader'
import LocationInput from '../../Components/LocationInput/LocationInput'
import { usePostEducationsRequestQuery } from '../../utils/Network/educationsApi'
import { IApplicationState } from '../../utils/Store/configureStore'
import {
  updateEducationSearch,
  updateLocation,
} from '../../utils/Store/educationFilterSlice'
import EmptyResult from '../../Components/EmptyResult/EmptyResult'
import ReliabilityBlock from '../../Components/ReliabilityBlock/ReliabilityBlock'

import EducationsList from './components/EducationsList/EducationsList'
import EducationFilterModal from './components/EducationFilterModal/EducationFilterModal'
import EducationFilterChips from './components/EducationFilterChipStack/EducationFilterChips'
import UtbildningarStart from './UtbildningarStart'
import { IEducationsResponse } from './types/IEducationsResponse.types'
import EducationTypeFilterButtons from './components/EducationTypeFilterButtons/EducationTypeFilterButtons'
import { Location, LocationType } from 'src/types/Location'
import { useTrackCustomClick } from '@trr/gtm-tracking'

interface FormValues {
  education: string
  location: Location
}

declare global {
  interface Window {
    dataLayer: Record<string, unknown>[]
  }
}

const Utbildningar = () => {
  const [filterModalOpen, setFilterModalOpen] = useState(false)
  const [hasFetched, setHasFetched] = useState(false)
  const dispatch = useDispatch()
  const trackCustomClick = useTrackCustomClick()

  const { filters, location, educationSearch } = useSelector(
    (state: IApplicationState) => state.educationFilter
  )

  const {
    utbildningar: { sokUtb, plats, sok, tomtResultat, tomtResultatUtb, filter },
  } = useEpiContent<Content>()

  const isDistance = !!filters?.other?.find((f) => f.id === 'distans')
  const educationType = filters?.educationTypes
    ? filters.educationTypes.map((f) => f.id)
    : null
  const educationForm = filters?.educationForms
    ? filters.educationForms.map((f) => f.id)
    : null
  const pacePercentage = filters?.studyPace?.id

  const { isSuccess, isFetching } = usePostEducationsRequestQuery(
    {
      query: educationSearch,
      offset: 0,
      limit: 100,
      municipalityCode:
        location.type === LocationType.municipality ? location.id : null,
      regionCode: location.type === LocationType.county ? location.id : null,
      isDistance,
      pacePercentage,
      educationType,
      educationForm,
    },
    { skip: !educationSearch && !location.id }
  )
  const { setFieldValue, handleSubmit, handleChange, values } = useFormik({
    initialValues: {
      education: educationSearch,
      location,
    },
    onSubmit: (values: FormValues) => {
      dispatch(updateEducationSearch(values.education))
      values?.location?.id
        ? dispatch(updateLocation(values.location))
        : dispatch(
            updateLocation({
              id: '',
              name: '',
            })
          )
    },
  })

  useEffect(() => {
    if (isFetching) {
      trackCustomClick('Sökning', {
        label: `Utbildningssökning gjordes`,
      })
    }
  }, [isFetching, trackCustomClick])

  const data = useSelector<IApplicationState>(
    (state) => state.educations.list
  ) as IEducationsResponse

  useEffect(() => {
    if (isSuccess) setHasFetched(true)
  }, [isSuccess])

  return (
    <>
      <InputHeader>
        <form onSubmit={handleSubmit}>
          <Box
            sx={{
              display: 'flex',
              gap: 2,
              flexDirection: {
                xs: 'column',
                sm: 'row',
              },
            }}
          >
            <TextField
              sx={{ backgroundColor: 'white' }}
              fullWidth
              id="education"
              name="education"
              type="search"
              value={values.education}
              label={sokUtb}
              onChange={handleChange}
            />
            <LocationInput
              value={values.location ?? null}
              defaultValue={location}
              label={plats}
              onLocationSelect={(v) => setFieldValue('location', v)}
            />
            <Button
              sx={{ marginLeft: { sm: 'auto', xs: 'unset' } }}
              type="submit"
              size="medium"
              variant="contained"
            >
              {sok}
            </Button>
          </Box>
        </form>
        <Box
          sx={{
            mt: 2,
            display: 'flex',
            gap: 1,
            flexWrap: 'wrap',
          }}
        >
          <EducationFilterChips />
        </Box>
        <Box
          sx={{
            marginTop: 2,
            display: 'flex',
            gap: 1,
            flexWrap: 'wrap',
          }}
        >
          <Button
            onClick={() => setFilterModalOpen(true)}
            startIcon={<FilterList />}
            size="small"
          >
            {filter}
          </Button>
        </Box>
      </InputHeader>

      {hasFetched && <EducationTypeFilterButtons />}
      {hasFetched ? (
        <EducationsList
          itemHeaderComponent="h2"
          showTypeToggle
          noResult={
            <EmptyResult heading={tomtResultat} description={tomtResultatUtb} />
          }
          educations={data.educations ?? []}
        />
      ) : (
        <UtbildningarStart />
      )}
      <ReliabilityBlock />
      {filterModalOpen && (
        <EducationFilterModal
          open={filterModalOpen}
          onCloseClick={() => setFilterModalOpen(false)}
        />
      )}
    </>
  )
}

export default Utbildningar
