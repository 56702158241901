import {
  combineReducers,
  configureStore,
  PreloadedState,
  ReducersMapObject,
} from '@reduxjs/toolkit'
import { setupListeners } from '@reduxjs/toolkit/query/react'
import { IIndustryArticle } from 'src/Features/Rapporter/types/IndustryArticle.type'

import { IFilter } from '../../types/IFilter.types'
import {
  educationParametersApi,
  educationParametersSlice,
} from '../Network/educationParametersApi'
import { educationsApi, educationsSlice } from '../Network/educationsApi'
import { locationsApi } from '../Network/locationsApi'
import { occupationApi, occupationSlice } from '../Network/occupationApi'
import {
  industryReportApi,
  industryReportSlice,
} from '../Network/industryReportApi'
import { IEducationsResponse } from '../../Features/Utbildning/types/IEducationsResponse.types'
import { IOccupationOutlook } from '../../Features/Yrke/types/IOccupationOutlook.types'
import {
  referenceDataApi,
  referenceDataSlice,
} from '../Network/referenceDataApi'
import { ISalaryStatistics } from '../../types/ISalaryStatistics.types'
import { competenceApi, competenceSlice } from '../Network/competenceApi'
import { ICompetence } from '../../types/ICompetence'

import {
  educationFilterSlice,
  IEducationFilterState,
} from './educationFilterSlice'
import { IEducation } from 'src/Features/Utbildning/types/IEducation.types'

export interface IApplicationState {
  occupation: {
    form: {
      occupationSearch: string
    }
  }

  educationParameters: {
    educationForms: IFilter[]
    educationTypes: IFilter[]
  }

  educations: {
    list: IEducationsResponse
    details: IEducation
    matchByOccupationList: IEducationsResponse
  }
  educationFilter: IEducationFilterState
  referenceData: {
    occupationalOutlook: { [key: string]: IOccupationOutlook }
    salaryStatistics: { [key: string]: ISalaryStatistics }
  }
  competence: {
    list: ICompetence[]
    selectedCompetences: ICompetence[]
  }
  industryReport: IIndustryArticle
}

const applicationStateReducers: ReducersMapObject<IApplicationState> = {
  occupation: occupationSlice.reducer,
  educationParameters: educationParametersSlice.reducer,
  educations: educationsSlice.reducer,
  educationFilter: educationFilterSlice.reducer,
  referenceData: referenceDataSlice.reducer,
  competence: competenceSlice.reducer,
  industryReport: industryReportSlice.reducer,
}

const rootReducer = combineReducers({
  ...applicationStateReducers,
  [occupationApi.reducerPath]: occupationApi.reducer,
  [educationParametersApi.reducerPath]: educationParametersApi.reducer,
  [locationsApi.reducerPath]: locationsApi.reducer,
  [educationsApi.reducerPath]: educationsApi.reducer,
  [referenceDataApi.reducerPath]: referenceDataApi.reducer,
  [competenceApi.reducerPath]: competenceApi.reducer,
  [industryReportApi.reducerPath]: industryReportApi.reducer,
})

export const setupStore = (preloadedState?: PreloadedState<RootState>) =>
  configureStore({
    reducer: rootReducer,
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware()
        .concat(occupationApi.middleware)
        .concat(educationParametersApi.middleware)
        .concat(locationsApi.middleware)
        .concat(educationsApi.middleware)
        .concat(referenceDataApi.middleware)
        .concat(competenceApi.middleware)
        .concat(industryReportApi.middleware),
    preloadedState,
  })

setupListeners(setupStore().dispatch)

const store = setupStore()

export type RootState = ReturnType<typeof rootReducer>
export type AppStore = ReturnType<typeof setupStore>
export type AppDispatch = AppStore['dispatch']

export default store
