import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import getConfig from '../Config/configService'
import { IOccupation } from '../../Features/Yrke/types/IOccupation.types'
import { IOccupationsResponse } from '../../Features/Yrke/types/IOccupationsResponse.types'

import { prepareHeaders } from './prepareHeaders'
import { IJobTitle } from 'src/types/IJobTitle.types'
import { IOccupationStatistics } from 'src/Features/Yrke/types/IOccupationStatistics.types'

const { API_URL } = getConfig()

export const occupationApi = createApi({
  reducerPath: 'occupation-api',
  baseQuery: fetchBaseQuery({
    baseUrl: `${API_URL}kompetens/prognosportal`,
    prepareHeaders,
  }),
  tagTypes: ['Occupation'],
  endpoints: (build) => ({
    getJobTitles: build.query<IJobTitle[], string>({
      query: (search) => ({
        url: `/occupation/match-by-job-title/${search.toLowerCase()}`,
      }),
    }),
    matchByText: build.query<IOccupationsResponse, string>({
      query: (text) => ({
        url: `/occupation/match-by-text`,
        method: 'POST',
        body: {
          competences: text,
          limit: 100,
          includeMetadata: true,
        },
      }),
    }),
    matchByEducationId: build.query<IOccupationsResponse, string>({
      query: (id) => ({
        url: `/occupation/match-by-education-id/${id}`,
        params: {
          limit: 100,
        },
      }),
    }),
    getOccupation: build.query<IOccupation, string>({
      query: (id) => ({
        url: `occupation/enrichedoccupation/${id}`,
        params: {
          includeMetadata: true,
        },
      }),
      providesTags: ['Occupation'],
    }),
    getOccupationStatistics: build.query<IOccupationStatistics, string>({
      query: (id) => ({
        url: `occupation/statistics/${id}`,
      }),
    }),
  }),
})

export const occupationSlice = createSlice({
  name: 'occupation',
  initialState: {
    form: {
      occupationSearch: '',
    },
    occupationStatistics: {} as { [key: string]: IOccupationStatistics },
    occupationsMatchedByText: {} as unknown as IOccupationsResponse,
    occupationsMatchedByEducation: {} as unknown as IOccupationsResponse,
    jobTitles: [] as unknown as IJobTitle[],
  },
  reducers: {
    updateOccupationSearch: (state, action: PayloadAction<string>) => {
      state.form.occupationSearch = action.payload
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      occupationApi.endpoints.matchByEducationId.matchFulfilled,
      (_state, action: { payload: IOccupationsResponse }) => {
        return { ..._state, occupationsMatchedByEducation: action.payload }
      }
    )
    builder.addMatcher(
      occupationApi.endpoints.matchByText.matchFulfilled,
      (_state, action: { payload: IOccupationsResponse }) => {
        return { ..._state, occupationsMatchedByText: action.payload }
      }
    )
    builder.addMatcher(
      occupationApi.endpoints.getOccupationStatistics.matchFulfilled,
      (_state, action: { payload: IOccupationStatistics }) => {
        return {
          ..._state,
          occupationStatistics: {
            ..._state.occupationStatistics,
            [action.payload?.ssyk]: action.payload,
          },
        }
      }
    )
  },
})

export const { updateOccupationSearch } = occupationSlice.actions

export const {
  useGetJobTitlesQuery,
  useMatchByTextQuery,
  useMatchByEducationIdQuery,
  useGetOccupationQuery,
  useGetOccupationStatisticsQuery,
} = occupationApi
