import { alpha, Box, Stack, Typography, useTheme } from '@mui/material'
import React from 'react'
import OutlookBarChart from '../OutlookBarChart/OutlookBarChart'
import PrognosCard from '../PrognosCard/PrognosCard'
import SourceMetaText from '../SourceMetaText/SourceMetaText'
import type { OccupationOutlook } from '../../types/OccupationOutlook.types'
import { useTranslation } from 'react-i18next'

interface OutlookProps {
  occupationOutlook: OccupationOutlook
}
const Outlook: React.FC<OutlookProps> = ({ occupationOutlook }) => {
  const { t: tKallor } = useTranslation('translation', {
    keyPrefix: 'kallor',
  })
  const { t: tYrke } = useTranslation('translation', {
    keyPrefix: 'yrke',
  })
  const { palette } = useTheme()
  const borderColor = alpha(palette.neutral?.main ?? 'rgb()', 0.16)

  if (!occupationOutlook) return null

  return (
    <>
      <Stack
        mt={{ xs: 0, sm: 0.5 }}
        gap={{ xs: 0, sm: 3 }}
        sx={{
          flexWrap: {
            xs: 'wrap',
            md: 'unset',
          },
        }}
        direction="row"
      >
        <Box
          key={occupationOutlook.id}
          sx={{
            backgroundColor: palette.surface?.blue,
            p: { xs: 2, sm: 5 },
            flexGrow: 1,
            borderTop: { xs: `1px solid ${borderColor}`, sm: 'none' },
          }}
          borderRadius={{ xs: 0, sm: 2 }}
        >
          <OutlookBarChart occupationOutlook={occupationOutlook} />
        </Box>

        {occupationOutlook && (
          <Box
            key={occupationOutlook.id}
            sx={{
              backgroundColor: palette.surface?.blue,
              p: { xs: 2, sm: 5 },
              flexGrow: 1,
              alignContent: 'space-evenly',
              borderTop: { xs: `1px solid ${borderColor}`, sm: 'none' },
            }}
            borderRadius={{ xs: 0, sm: 2 }}
          >
            <PrognosCard occupationOutlook={occupationOutlook} />
          </Box>
        )}
      </Stack>
      <Stack
        sx={{
          mt: { xs: 0, sm: 3 },
          backgroundColor: palette.surface?.blue,
          p: { xs: 2, sm: 5 },
          mb: { xs: 0, sm: 3 },
          borderTop: { xs: `1px solid ${borderColor}`, sm: 'none' },
        }}
        borderRadius={{ xs: 0, sm: 2 }}
      >
        <Typography gutterBottom variant="h4" component="h3">
          {tYrke('prognos')}
        </Typography>
        <Typography gutterBottom variant="body1">
          {occupationOutlook?.ingress}
        </Typography>
        {occupationOutlook && (
          <SourceMetaText
            text={`${tKallor('kallaAF')} (${occupationOutlook?.year}).`}
          />
        )}
      </Stack>
      {occupationOutlook.textRekryteringssituation !== '' && (
        <Stack
          sx={{
            mt: { xs: 0, sm: 3 },
            backgroundColor: palette.surface?.blue,
            p: { xs: 2, sm: 5 },
            mb: { xs: 0, sm: 3 },
            borderTop: { xs: `1px solid ${borderColor}`, sm: 'none' },
          }}
          borderRadius={{ xs: '0 0 8px 8px', sm: 2 }}
        >
          <Typography gutterBottom variant="h4" component="h3">
            {tYrke('efterfragan')}
          </Typography>
          <Typography gutterBottom variant="body1">
            {occupationOutlook?.textRekryteringssituation}
          </Typography>
          {occupationOutlook && (
            <SourceMetaText
              text={`${tKallor('kallaAF')} (${occupationOutlook?.year}).`}
            />
          )}
        </Stack>
      )}
    </>
  )
}

export default Outlook
