import { Box, Stack, Typography } from '@mui/material'
import React from 'react'

interface IconHeaderProps {
  icon: JSX.Element
  label?: string
  background?: string
  disabled?: boolean
}

export const IconHeader: React.FC<IconHeaderProps> = ({
  icon,
  label,
  background,
  disabled,
}) => {
  if (!label) return null
  return (
    <Stack
      data-testid="icon-text-wrapper"
      alignItems="center"
      spacing={2}
      direction="row"
    >
      <Box
        display="flex"
        flexShrink="0"
        marginY={0.5}
        borderRadius={2}
        sx={{
          width: { xs: '24px', sm: '48px' },
          height: { xs: '24px', sm: '48px' },
          backgroundColor: { background },
          filter: disabled ? 'grayscale(1)' : '',
        }}
        justifyContent="center"
        alignItems="center"
      >
        {icon}
      </Box>
      <Box>
        <Typography
          variant="h6"
          component="h3"
          sx={{
            marginRight: { xs: 4, sm: 0 },
            wordBreak: 'break-word',
            overflowWrap: 'break-word',
          }}
        >
          {label}
        </Typography>
      </Box>
    </Stack>
  )
}
