import React from 'react'
import DelayedSkeleton from 'src/components/DelayedSkeleton/DelayedSkeleton'

const YrkeSkeleton: React.FC = () => {
  return (
    <>
      <DelayedSkeleton
        sx={{ mb: 5 }}
        variant="rectangular"
        width={'70%'}
        height={50}
      />
      <DelayedSkeleton
        sx={{ mb: 3 }}
        variant="rounded"
        width={250}
        height={47}
      />
      <DelayedSkeleton
        sx={{ mb: 3 }}
        variant="rectangular"
        width={'100%'}
        height={350}
      />
    </>
  )
}

export default YrkeSkeleton
