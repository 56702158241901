import React from 'react'
import { Card, CardActionArea, Link, capitalize } from '@mui/material'

import EducationDetails from '../EducationDetails/EducationDetails'
import { CreditTypes } from 'src/types/CreditTypes'
import { Education } from '../../types/Education.types'
import { routes } from 'src/utils/router/Routes'

interface EducationCardProps {
  education: Education
  id: string
  hideBorder?: boolean
  isSummary?: boolean
  linkTodetails?: boolean
  headerComponent: string
}

const EducationCard: React.FC<EducationCardProps> = ({
  education,
  id,
  headerComponent,
}) => {
  const subheader = () =>
    `${education?.credits?.credits ? education?.credits?.credits : ''} ${CreditTypes[education?.credits?.code] ? CreditTypes[education?.credits?.code] + ',' : ''} ${education?.provider ? education?.provider + ',' : ''} ${education?.educationForm ? capitalize(education?.educationForm ?? '') + ',' : ''} ${education?.educationType ? capitalize(education?.educationType ?? '') : ''}`
  const ariaLabel = `${education.title} (${education.code}) ${subheader()}`

  return (
    <Card
      data-testid="education-card"
      sx={{ marginTop: 3, backgroundColor: 'transparent' }}
      component="article"
    >
      <CardActionArea
        sx={{ padding: 3 }}
        component={Link}
        href={`${routes.start}utbildning/${id}`}
        aria-label={ariaLabel}
        data-gtm-label={`${education.title} (${education.code})`}
      >
        <EducationDetails
          isSummary
          education={education}
          subheader={subheader()}
          headerComponent={headerComponent}
        />
      </CardActionArea>
    </Card>
  )
}

export default EducationCard
