import React from 'react'

import { FilterType } from '../Filter/Filter'

import { FilterOptionGroupAccordion } from './FilterOptionGroupAccordion'
import { FilterOption } from './FilterOption'

const MobileFilter = ({
  filter,
  handleSetFilters,
}: {
  filter: FilterType
  handleSetFilters: (filterId: string, value: string) => void
}) => {
  return (
    <FilterOptionGroupAccordion
      title={filter.label}
      selectCount={filter.filters.length}
    >
      <FilterOption
        selectedFilters={[]}
        filter={filter}
        handleSetFilters={(filterId: string, filterLabel: string) =>
          handleSetFilters(filterId, filterLabel)
        }
        buttonProps={{ sx: { pl: 0 } }}
      />
    </FilterOptionGroupAccordion>
  )
}

export default MobileFilter
