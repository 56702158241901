/* eslint-disable @typescript-eslint/restrict-template-expressions */
import { Card, CardActionArea, capitalize } from '@mui/material'
import React from 'react'
import { Link } from 'react-router-dom'

import { IEducation } from '../../types/IEducation.types'
import { encodeUrl } from '../../../../utils/Helpers/codeUri'
import EducationDetails from '../EducationDetails/EducationDetails'
import { CreditTypes } from 'src/types/CreditTypes'

interface IEducationCardProps {
  education: IEducation
  id: string
  hideBorder?: boolean
  isSummary?: boolean
  linkTodetails?: boolean
  headerComponent: string
}

const EducationCard: React.FC<IEducationCardProps> = ({
  education,
  id,
  headerComponent,
}) => {
  const encodedUrl = encodeUrl(id)

  const subheader = () =>
    `${education?.credits?.credits ? education?.credits?.credits : ''} ${CreditTypes[education?.credits?.code] ? CreditTypes[education?.credits?.code] + ',' : ''} ${education?.provider ? education?.provider + ',' : ''} ${education?.educationForm ? capitalize(education?.educationForm) + ',' : ''} ${education?.educationType ? capitalize(education?.educationType) : ''}`

  const ariaLabel = `${education.title} (${education.code}) ${subheader()}`
  return (
    <Card
      sx={{ marginTop: 3, backgroundColor: 'transparent' }}
      component="article"
    >
      <CardActionArea
        sx={{ padding: 3 }}
        component={Link}
        to={`/utbildningar/${encodedUrl}`}
        aria-label={ariaLabel}
        data-gtm-label={`${education.title} (${education.code})`}
      >
        <EducationDetails
          isSummary
          education={education}
          subheader={subheader()}
          headerComponent={headerComponent}
        />
      </CardActionArea>
    </Card>
  )
}

export default EducationCard
