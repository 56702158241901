/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable @typescript-eslint/restrict-template-expressions */
import { Box, Button, Stack, Typography, useTheme } from '@mui/material'
import { OpenInNew } from '@mui/icons-material'
import React from 'react'
import { Content } from 'src/App/App.types'
import { useEpiContent } from '@trr/app-shell-data'

import EpiImage from '../EpiImage/EpiImage'
import useDeviceSize from '../../utils/Hooks/useDeviceSize'

// eslint-disable-next-line @typescript-eslint/consistent-type-definitions
type IReliabilityBlockContent = {
  illustrationsamarbete: {
    url: string
  }
  SCBlink: {
    url: string
    header: string
  }
  AFlink: {
    url: string
    header: string
  }
  palitlighetsblock: {
    header: string
    paragraph1: string
    paragraph2: string
  }
}
const ReliabilityBlock = () => {
  const { mediumScreensUp } = useDeviceSize()
  const { palette } = useTheme()
  const { palitlighetsblock, illustrationsamarbete, AFlink, SCBlink } =
    useEpiContent<IReliabilityBlockContent>()
  const {
    kallor: { openInNew },
  } = useEpiContent<Content>()
  if (!palitlighetsblock) return null
  const { header, paragraph1, paragraph2 } = palitlighetsblock
  return (
    <Box
      sx={{
        mt: 4,
        padding: { sm: 5, xs: 2 },
        backgroundColor: palette.surface?.purple,
      }}
    >
      <Stack sx={{ flexDirection: { md: 'row' } }}>
        <Box
          sx={{
            justifyContent: 'center',
            flexDirection: 'column',
            display: 'flex',
          }}
        >
          {header && (
            <Typography gutterBottom variant="h3" component="h2">
              {header}
            </Typography>
          )}
          {paragraph1 && (
            <Typography gutterBottom variant="body1">
              {paragraph1}
            </Typography>
          )}
          {paragraph2 && (
            <Typography gutterBottom variant="body1">
              {paragraph2}
            </Typography>
          )}
          <Stack
            sx={{
              flexDirection: { sm: 'row' },
              flexFlow: 'wrap',
              mb: 2,
              mt: 2,
            }}
            gap={2}
          >
            {[AFlink, SCBlink].map((link) => {
              const ariaLabel = `${link?.header} - ${openInNew}`
              if (link)
                return (
                  <Button
                    key={link?.url}
                    component={'a'}
                    target="_blank"
                    startIcon={<OpenInNew />}
                    href={link?.url}
                    variant="outlined"
                    aria-label={ariaLabel}
                  >
                    {link?.header}
                  </Button>
                )
            })}
          </Stack>
        </Box>
        {illustrationsamarbete && mediumScreensUp && (
          <EpiImage
            width={'400px'}
            height={'346px'}
            url={illustrationsamarbete?.url}
          />
        )}
      </Stack>
    </Box>
  )
}

export default ReliabilityBlock
