import React from 'react'
import { Box, SxProps } from '@mui/material'

const WysiwygEditor = ({
  htmlFormattedString,
  styles,
}: {
  htmlFormattedString: string
  styles?: SxProps
}): JSX.Element => (
  <Box
    sx={{
      '&': { wordWrap: 'break-word' },
      '& h4': { marginTop: '5px' },
      '& p': { marginBottom: '10px' },
      '& a': { color: 'rgb(98, 34, 195)', textDecoration: 'none' },
      '& a:hover': { textDecoration: 'underline' },
      ...styles,
    }}
    dangerouslySetInnerHTML={{ __html: htmlFormattedString }}
  />
)

export default WysiwygEditor
