import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { createSlice } from '@reduxjs/toolkit'

import getConfig from '../Config/configService'
import { IEducationsResponse } from '../../Features/Utbildning/types/IEducationsResponse.types'
import { IEducationsMatchByOccupationResponse } from '../../Features/Utbildning/types/IEducationsMatchByOccupationResponse.types'
import { IPostEducationsRequest } from '../../Features/Utbildning/types/IPostEducationsRequest.types'

import { prepareHeaders } from './prepareHeaders'
import { IEducation } from 'src/Features/Utbildning/types/IEducation.types'

const { API_URL } = getConfig()

export interface IGetEducationsRequest {
  query: string
  municipality?: string
  studyPace?: number | null
  educationTypes?: string[]
  page?: number
  isDistance?: boolean
}

export const educationsApi = createApi({
  reducerPath: 'educations-api',
  baseQuery: fetchBaseQuery({
    baseUrl: `${API_URL}kompetens/prognosportal/educations`,
    prepareHeaders,
  }),
  refetchOnMountOrArgChange: true,
  tagTypes: ['Educations'],

  endpoints: (build) => ({
    postEducationsRequest: build.query<
      IEducationsResponse,
      IPostEducationsRequest
    >({
      providesTags: ['Educations'],
      query: (formData) => ({
        url: '/',
        method: 'POST',
        body: formData,
      }),
      transformResponse: (
        response: IEducationsResponse
      ): IEducationsResponse => ({
        hits: response.hits,
        page: response.page,
        educations: response.educations.map((el: IEducation) => ({
          ...el,
          eventInformation: {
            ...el.eventInformation,
            startDate: el.startDate ?? '',
            endDate: el.endDate ?? '',
          },
        })),
      }),
    }),
    getEducation: build.query<IEducation, string>({
      providesTags: ['Educations'],
      query: (id) => `${id}`,
    }),
    postMatchByOccupationId: build.query<
      IEducationsMatchByOccupationResponse,
      string
    >({
      query: (id) => ({
        url: `match-by-occupation/${id}`,
        method: 'GET',
      }),
    }),
  }),
})

export const educationsSlice = createSlice({
  name: 'educations',
  initialState: {
    list: [] as unknown as IEducationsResponse,
    matchByOccupationList: [] as unknown as IEducationsResponse,
    details: {} as unknown as IEducation,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addMatcher(
      educationsApi.endpoints.postEducationsRequest.matchFulfilled,
      (_state, action: { payload: IEducationsResponse }) => {
        return {
          ..._state,
          list: {
            ...action.payload,
            result: action.payload.educations,
          },
        }
      }
    )

    builder.addMatcher(
      educationsApi.endpoints.getEducation.matchFulfilled,
      (_state, action: { payload: IEducation }) => {
        return { ..._state, details: action.payload }
      }
    )
    builder.addMatcher(
      educationsApi.endpoints.postMatchByOccupationId.matchFulfilled,
      (_state, action: { payload: IEducationsMatchByOccupationResponse }) => {
        return {
          ..._state,
          matchByOccupationList: action.payload,
        }
      }
    )
  },
})

export const {
  useGetEducationQuery,
  usePostMatchByOccupationIdQuery,
  usePostEducationsRequestQuery,
} = educationsApi
