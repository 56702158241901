import React from 'react'

import getConfig from '../../utils/Config/configService'
import {
  getImageQuery,
  createImageAltTextFromFileName,
} from '../../utils/Helpers/getImageQuery'

const EpiImage = ({
  url,
  width = '300',
  height = 'fit-content',
}: {
  url: string
  width?: string
  height?: string
}): JSX.Element => {
  const { MEDIA_URL } = getConfig()

  if (!url) return <></>
  return (
    <>
      <img
        width={width}
        src={getImageQuery(MEDIA_URL + url, 800)}
        alt={createImageAltTextFromFileName(url)}
        height={height}
        aria-hidden="true"
      />
    </>
  )
}

export default EpiImage
