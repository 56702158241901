/* eslint-disable @typescript-eslint/no-unsafe-return */
import {
  Autocomplete,
  InputAdornment,
  Stack,
  TextField,
  Typography,
  createFilterOptions,
  useTheme,
} from '@mui/material'
import React, { SyntheticEvent } from 'react'

import {
  useGetCountiesQuery,
  useGetMunicipalitiesQuery,
} from '../../utils/Network/locationsApi'
import { Location } from 'src/types/Location'

interface ILocationInputProps {
  label?: string
  onLocationSelect: (value: Location) => void
  defaultValue: Location
  value: Location
}

const LocationInput: React.FC<ILocationInputProps> = ({
  onLocationSelect,
  label = 'Var finns utbildningen?',
  value,
}) => {
  const { palette } = useTheme()
  const { isFetching, data = [] as Location[] } = useGetMunicipalitiesQuery()
  const { isFetching: isFetchingCounties, data: counties = [] as Location[] } =
    useGetCountiesQuery()

  // Combine both municipalities and counties
  const municipalitiesAndCounties = counties
    .concat(data)
    .sort((a, b) => (a.name > b.name ? 1 : -1))

  const filterOptions = createFilterOptions({
    matchFrom: 'start',
    stringify: (option: Location) => option.name,
  })

  return (
    <Autocomplete
      filterOptions={filterOptions}
      fullWidth
      disablePortal
      disabled={isFetching || isFetchingCounties}
      options={municipalitiesAndCounties}
      value={value}
      getOptionLabel={(option) => option.name}
      renderOption={(props, option) => {
        return (
          <li {...props} key={option.id}>
            <Stack direction={'row'} alignItems={'baseline'} width={'100%'}>
              <Typography variant="body1">{option.name}</Typography>
              <Typography
                variant="body2"
                color={palette.text.secondary}
                sx={{ marginLeft: 'auto' }}
              >
                {option.type}
              </Typography>
            </Stack>
          </li>
        )
      }}
      sx={{ backgroundColor: 'white' }}
      renderInput={(params) => (
        <TextField
          {...params}
          label={label}
          InputProps={{
            ...params.InputProps,
            endAdornment: value?.name !== '' && (
              <>
                <InputAdornment position="end">{value?.type}</InputAdornment>
                {params.InputProps.endAdornment}
              </>
            ),
          }}
        />
      )}
      isOptionEqualToValue={(option: Location, value: Location) => {
        if (!value.id) return false
        if (
          value === option ||
          value ===
            ({
              id: '',
              name: '',
            } as Location)
        ) {
          return true
        } else return false
      }}
      onChange={(event: SyntheticEvent<Element, Event>, newValue: Location) => {
        onLocationSelect(newValue)
      }}
    />
  )
}

export default LocationInput
