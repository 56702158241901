import { Box, Stack, Typography, useTheme } from '@mui/material'
import React from 'react'
import { useEpiContent } from '@trr/app-shell-data'

import { Content } from '../../../../App/App.types'
import { IOccupationOutlook } from '../../types/IOccupationOutlook.types'
import OutlookBarChart from '../OutlookBarChart/OutlookBarChart'
import SourceMetaText from '../SourceMetaText/SourceMetaText'
import PrognosCard from '../PrognosCard/PrognosCard'

interface IOutlookProps {
  occupationOutlook: IOccupationOutlook
}
const Outlook: React.FC<IOutlookProps> = ({ occupationOutlook }) => {
  const {
    kallor: { kallaAF },
    yrke: { prognos, efterfragan },
  } = useEpiContent<Content>()
  const { palette } = useTheme()

  if (!occupationOutlook) return null

  return (
    <>
      <Stack
        gap={1}
        sx={{
          flexWrap: {
            xs: 'wrap',
            md: 'unset',
          },
        }}
        direction="row"
      >
        <Box
          key={occupationOutlook.id}
          sx={{
            backgroundColor: palette.surface?.blue,
            p: { xs: 2, sm: 5 },
            flexGrow: 1,
          }}
        >
          <OutlookBarChart occupationOutlook={occupationOutlook} />
        </Box>

        {occupationOutlook && (
          <Box
            key={occupationOutlook.id}
            sx={{
              backgroundColor: palette.surface?.blue,
              p: { xs: 2, sm: 5 },
              flexGrow: 1,
              alignContent: 'space-evenly',
            }}
          >
            <PrognosCard occupationOutlook={occupationOutlook} />
          </Box>
        )}
      </Stack>
      <Stack
        sx={{
          mt: 1,
          backgroundColor: palette.surface?.blue,
          p: { xs: 2, sm: 5 },
          mb: 0.5,
        }}
      >
        <Typography gutterBottom variant="h3" component="h2">
          {prognos}
        </Typography>
        <Typography gutterBottom variant="body1">
          {occupationOutlook?.ingress}
        </Typography>
        {occupationOutlook && (
          <SourceMetaText text={`${kallaAF} (${occupationOutlook?.year})`} />
        )}
      </Stack>
      {occupationOutlook.textRekryteringssituation !== '' && (
        <Stack
          sx={{
            mt: 1,
            backgroundColor: palette.surface?.blue,
            p: { xs: 2, sm: 5 },
            mb: 0.5,
          }}
        >
          <Typography gutterBottom variant="h3" component="h2">
            {efterfragan}
          </Typography>
          <Typography gutterBottom variant="body1">
            {occupationOutlook?.textRekryteringssituation}
          </Typography>
          {occupationOutlook && (
            <SourceMetaText text={`${kallaAF} (${occupationOutlook?.year})`} />
          )}
        </Stack>
      )}
    </>
  )
}

export default Outlook
