import React from 'react'
import {
  Checkbox,
  List,
  ListItem,
  ListItemButton,
  ListItemButtonProps,
  ListItemIcon,
  ListItemText,
  Typography,
  colors,
  useMediaQuery,
  useTheme,
} from '@mui/material'
import { useTranslation } from 'react-i18next'
import { Data, FilterType } from '../Filter/Filter'

interface FilterOptionProps {
  subtitle?: string
  filter: FilterType
  selectedFilters: string[]
  buttonProps?: ListItemButtonProps
  handleSetFilters: (filterId: string, value: string) => void
}

export const FilterOption = ({
  subtitle,
  filter,
  buttonProps,
  handleSetFilters,
}: FilterOptionProps) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'filtrering',
  })
  const theme = useTheme()
  const isDesktop = useMediaQuery(theme.breakpoints.up('md'))

  return (
    <List sx={{ paddingBottom: '.5rem' }} disablePadding>
      {subtitle && (
        <Typography
          sx={{
            padding: isDesktop ? '0 0 0 .7rem' : 0,
            marginBottom: '.5rem',
            color: colors.grey[600],
          }}
          variant="subtitle2"
          gutterBottom
        >
          {subtitle}
        </Typography>
      )}
      {filter.data?.length === 0 && (
        <Typography variant="subtitle2">{t('noFilters')}</Typography>
      )}
      {filter.data?.map((option: Data) => (
        <ListItem key={option.id} disablePadding divider={false}>
          <ListItemButton
            role="button"
            sx={{ paddingTop: 0, paddingBottom: 0, left: -5 }}
            onClick={() => handleSetFilters(filter.id, option.id)}
            dense
            {...buttonProps}
          >
            <ListItemIcon sx={{ minWidth: 0 }}>
              <Checkbox
                edge="start"
                checked={
                  filter.filters.some((filter) => filter === option.id) ?? false
                }
                tabIndex={-1}
                disableRipple
                inputProps={{ 'aria-labelledby': option.label }}
              />
            </ListItemIcon>
            <ListItemText id={option.id} primary={option.label} />
          </ListItemButton>
        </ListItem>
      ))}
    </List>
  )
}
