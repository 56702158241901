import { Info } from '@mui/icons-material'
import {
  alpha,
  capitalize,
  SelectChangeEvent,
  Stack,
  Typography,
  useTheme,
} from '@mui/material'
import React, { useEffect, useState } from 'react'
import ChipSelect, { MenuItem } from 'src/components/ChipSelect/ChipSelect'
import SourceMetaText from '../../SourceMetaText/SourceMetaText'
import { SectorSalaryStatistics as SectorSalaryStatisticsType } from 'src/features/Yrke/types/OccupationStatistics.types'
import { useTranslation } from 'react-i18next'
import { useEpiContent } from '@trr/app-shell-data'
import { Content } from 'src/App/App.types'

interface SectorSalaryStatisticsProps {
  sectorSalaryStatistics: SectorSalaryStatisticsType | undefined
  horisontal?: boolean
}

const SectorSalaryStatistics: React.FC<SectorSalaryStatisticsProps> = ({
  sectorSalaryStatistics,
  horisontal,
}) => {
  const { yrke } = useEpiContent<Content>()
  const { palette } = useTheme()
  const { t: tYrke } = useTranslation('translation', {
    keyPrefix: 'yrke',
  })
  const { t: tKallor } = useTranslation('translation', {
    keyPrefix: 'kallor',
  })
  const { t: tScbStatistik } = useTranslation('translation', {
    keyPrefix: 'scbStatistik',
  })
  const [salaryGenderValue, setSalaryGenderValue] = useState<string | null>('')
  const [salaryGenderOpen, setSalaryGenderOpen] = useState(false)

  const [salarySectorValue, setSalarySectorValue] = useState<string | null>('0')
  const [salarySectorOpen, setSalarySectorOpen] = useState(false)

  const validSectors = ['0', '1-3', '4-5']

  const [selectedSalary, setSelectedSalary] = useState(0)
  const [salaryAvailable, setSalaryAvailable] = useState(false)

  const menuItems = sectorSalaryStatistics?.sectors
    .filter((s) => validSectors.indexOf(s.sector) > -1)
    .map((sector) => ({
      name: capitalize(sector.sectorName ?? ''),
      value: sector.sector,
    })) as MenuItem[]
  const onSelectSector = (event: SelectChangeEvent) => {
    setSalarySectorValue(event.target.value)
  }
  const onSelectGender = (event: SelectChangeEvent) => {
    setSalaryGenderValue(event.target.value)
  }

  const handleOnSalarySectorClick = () => {
    setSalarySectorOpen(salarySectorOpen ? false : true)
  }
  const handleOnSalaryGenderClick = () => {
    setSalaryGenderOpen(salaryGenderOpen ? false : true)
  }

  const genderMenuItems = [
    {
      name: 'Samtliga kön',
      value: '',
    },
    {
      name: capitalize(tYrke('women') ?? ''),
      value: 'women',
    },
    {
      name: capitalize(tYrke('men') ?? ''),
      value: 'men',
    },
  ] as MenuItem[]

  useEffect(() => {
    const selectedSector = sectorSalaryStatistics?.sectors.find(
      (el) => el.sector === salarySectorValue
    )
    let selectedValue = selectedSector?.total
    if (salaryGenderValue === 'men') {
      selectedValue = selectedSector?.men
    } else if (salaryGenderValue === 'women') {
      selectedValue = selectedSector?.women
    }
    setSalaryAvailable(selectedValue !== null)
    setSelectedSalary(selectedValue ?? 0)
  }, [sectorSalaryStatistics, salarySectorValue, salaryGenderValue])

  const borderColor = palette.neutral?.main
    ? alpha(palette.neutral?.main, 0.16)
    : ''

  if (!sectorSalaryStatistics) return null

  return (
    <Stack
      sx={{
        backgroundColor: palette.surface?.blue,
        p: { xs: 2, sm: 5 },
        mt: { xs: 0, sm: 3 },
        borderTop: { xs: `1px solid ${borderColor}`, sm: 'none' },
      }}
      borderRadius={{ xs: 0, sm: 2 }}
      flex={{ xs: '1', md: horisontal ? 'auto' : '1' }}
      direction="column"
      justifyContent="space-between"
    >
      <Typography variant="h4" component="h3">
        {yrke.sectorSalaryHeader}
      </Typography>
      <Typography variant="body1" component="p" marginBottom={3}>
        {yrke.sectorSalaryPreamble}
      </Typography>
      <Stack direction={{ xs: 'column', sm: 'row' }} gap={1}>
        <ChipSelect
          value={salaryGenderValue}
          label={capitalize(
            genderMenuItems.find((item) => item.value === salaryGenderValue)
              ?.name ?? ''
          )}
          open={salaryGenderOpen}
          menuItems={genderMenuItems}
          onChange={onSelectGender}
          onClick={handleOnSalaryGenderClick}
        />
        <ChipSelect
          value={salarySectorValue}
          label={capitalize(
            sectorSalaryStatistics?.sectors.find(
              (sector) => sector.sector === salarySectorValue
            )?.sectorName ?? ''
          )}
          open={salarySectorOpen}
          menuItems={menuItems}
          onChange={onSelectSector}
          onClick={handleOnSalarySectorClick}
        />
      </Stack>
      {!salaryAvailable && (
        <Stack
          direction="row"
          alignItems="center"
          gap={1}
          marginTop={2}
          paddingTop={{ xs: '4px', sm: '6px' }}
          paddingBottom={{ xs: '4px', sm: '6px' }}
        >
          <Info />
          <Typography variant="h6" component="p">
            {tScbStatistik('statistikSaknas')}
          </Typography>
        </Stack>
      )}
      {salaryAvailable && (
        <Typography variant="h3" component="p" marginTop={2}>
          {selectedSalary.toLocaleString('sv-SE')} {tYrke('kr')}
        </Typography>
      )}
      <SourceMetaText
        text={`${tKallor('kallaSCB')} (${sectorSalaryStatistics.year}).`}
      />
    </Stack>
  )
}

export default SectorSalaryStatistics
