import React from 'react'
import { Box, Stack, Typography, useTheme } from '@mui/material'
import { useEpiContent } from '@trr/app-shell-data'

import { Content } from '../../../../App/App.types'
import { IOccupationOutlook } from '../../types/IOccupationOutlook.types'

interface IOutlookBarChartProps {
  occupationOutlook: IOccupationOutlook
}
const OutlookBarChart: React.FC<IOutlookBarChartProps> = ({
  occupationOutlook,
}) => {
  const {
    yrke: { tillArbete },
  } = useEpiContent<Content>()
  const { palette } = useTheme()
  const steps = [
    {
      percentage: '25',
      active: occupationOutlook.outlookValue === 25,
    },
    {
      percentage: '50',
      active: occupationOutlook.outlookValue === 50,
    },
    {
      percentage: '75',
      active: occupationOutlook.outlookValue === 75,
    },
  ]
  const arialabel = `${occupationOutlook.outlookLabel ?? ''} ${tillArbete} ${
    occupationOutlook.year
  }`

  return (
    <Stack
      direction="row"
      gap={2}
      role="figure"
      aria-label={arialabel}
      alignItems={'center'}
    >
      <Stack alignItems={'flex-end'} gap={1.5} direction="row" flexGrow={1}>
        {steps.map((o) => (
          <Box
            key={o.percentage}
            sx={{
              borderRadius: '5px',
              width: '50px',
              height: o.percentage + 'px',
              backgroundColor: o.active
                ? palette.info.main
                : palette.info.light,
            }}
          />
        ))}
      </Stack>
      <Stack flexGrow={1}>
        <Typography variant="h5" component="p">
          {occupationOutlook.outlookLabel}
        </Typography>
        <Typography variant="body1">
          {`${tillArbete} ${occupationOutlook.year}`}
        </Typography>
      </Stack>
    </Stack>
  )
}

export default OutlookBarChart
