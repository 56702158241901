import React from 'react'
import { Box, Stack, Typography, useTheme } from '@mui/material'
import { useEpiContent } from '@trr/app-shell-data'
import ArrowOutwardRoundedIcon from '@mui/icons-material/ArrowOutwardRounded'
import ArrowForwardRoundedIcon from '@mui/icons-material/ArrowForwardRounded'
import ArrowDownwardRounded from '@mui/icons-material/ArrowDownward'

import { Content } from '../../../../App/App.types'
import { IOccupationOutlook } from '../../types/IOccupationOutlook.types'

interface IOutlookBarChartProps {
  occupationOutlook: IOccupationOutlook
}
const PrognosCard: React.FC<IOutlookBarChartProps> = ({
  occupationOutlook,
}) => {
  const {
    yrke: {
      femArsSikt,
      oforandradEfterfragan,
      okandeEfterfragan,
      minskandeEfterfragan,
    },
  } = useEpiContent<Content>()
  const { palette } = useTheme()

  if (!occupationOutlook.prognos) return null

  return (
    <Stack
      direction="row"
      gap={6}
      alignItems={'center'}
      alignContent={'space-evenly'}
    >
      <Box>
        {occupationOutlook.prognos === 'vara oförändrad' ? (
          <ArrowForwardRoundedIcon
            color="info"
            sx={{
              width: '50px',
              height: '50px',
              fill: palette.neutral?.light,
            }}
          />
        ) : occupationOutlook.prognos === 'öka' ? (
          <ArrowOutwardRoundedIcon
            color="secondary"
            sx={{ width: '50px', height: '50px' }}
          />
        ) : (
          <ArrowDownwardRounded
            color="error"
            sx={{ width: '50px', height: '50px' }}
          />
        )}
      </Box>
      <Stack flexGrow={1}>
        <Typography variant="h5" component="p">
          {occupationOutlook.prognos === 'vara oförändrad'
            ? oforandradEfterfragan
            : occupationOutlook.prognos === 'öka'
              ? okandeEfterfragan
              : minskandeEfterfragan}
        </Typography>
        <Typography variant="body1">{femArsSikt}</Typography>
      </Stack>
    </Stack>
  )
}

export default PrognosCard
