import React from 'react'

const ArrowRightIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    role="img"
    preserveAspectRatio="xMidYMin slice"
    width="8"
    height="8"
    viewBox="0 0 24 24"
    style={{ fill: 'rgb(26, 26, 26)' }}
  >
    <path
      fill="inherit"
      fillRule="evenodd"
      d="M19.17 10.243L7.299.433c-.832-.657-2.072-.555-2.77.227-.675.756-.599 1.876.173 2.545l10.381 8.579a.24.24 0 0 1 0 .376l-10.38 8.578c-.832.656-.94 1.82-.243 2.602.698.782 1.938.884 2.77.228l.069-.057 11.866-9.807c.53-.438.834-1.07.835-1.732 0-.662-.302-1.292-.83-1.73z"
    />
  </svg>
)

export default ArrowRightIcon
