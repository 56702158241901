import { Box } from '@mui/material'
import React from 'react'

import EducationDetails from './components/EducationDetails/EducationDetails'
import { useGetEducationQuery } from 'src/api/educations'
import { useMatchByEducationIdQuery } from 'src/api/occupation'
import DelayedSkeleton from 'src/components/DelayedSkeleton/DelayedSkeleton'
import EmptyResult from 'src/components/EmptyResult/EmptyResult'
import PageWrapper from 'src/components/PageWrapper/PageWrapper'
import OccupationsList from '../Yrke/components/OccupationsList/OccupationsList'
import { useTranslation } from 'react-i18next'
import { useSelectedId } from 'src/utils/hooks/useSelectedId'

const Utbildning: React.FC = () => {
  const id = useSelectedId()
  const { t } = useTranslation('translation', { keyPrefix: 'utbildning' })
  const { data: educationData, isLoading, isError } = useGetEducationQuery(id)
  const { data: occupationData, isSuccess } = useMatchByEducationIdQuery(id)

  if (!educationData)
    return (
      <DelayedSkeleton
        sx={{ mb: 3 }}
        variant="rectangular"
        width={'100%'}
        height={477}
      />
    )

  return (
    <>
      <PageWrapper
        isLoading={isLoading}
        errorText={t('errorFetchUtb')}
        errorLoadingData={isError}
      >
        <Box
          sx={{ p: { xs: 2, sm: 5 }, mb: 3 }}
          bgcolor="rgba(242, 237, 248, 1)"
        >
          <EducationDetails
            headerComponent="h1"
            education={educationData}
            isSummary={false}
          />
        </Box>

        {isSuccess && (
          <OccupationsList
            itemHeaderComponent="h3"
            noResult={
              <EmptyResult
                heading={t('tomtResultat')}
                description={t('tomtResultatYrken')}
              />
            }
            header={t('titleYrken')}
            occupations={occupationData?.occupations ?? []}
          />
        )}
      </PageWrapper>
    </>
  )
}

export default Utbildning
