import { Occupation } from 'src/features/Yrke/types/Occupation.types'
import { OccupationsResponse } from 'src/features/Yrke/types/OccupationsResponse.types'
import { OccupationStatistics } from 'src/features/Yrke/types/OccupationStatistics.types'
import { JobTitle } from 'src/types/JobTitle.types'
import kompetensApi from '../kompetensApi'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'

export const occupationApi = kompetensApi.injectEndpoints({
  endpoints: (build) => ({
    getJobTitles: build.query<JobTitle[], string>({
      query: (search) => ({
        url: `/prognosportal/occupation/match-by-job-title/${search.toLowerCase()}`,
      }),
    }),
    matchByText: build.query<OccupationsResponse, string>({
      query: (text) => ({
        url: `/prognosportal/occupation/match-by-text`,
        method: 'POST',
        body: {
          competences: text,
          limit: 100,
          includeMetadata: true,
        },
      }),
    }),
    matchByEducationId: build.query<OccupationsResponse, string>({
      query: (id) => ({
        url: `/prognosportal/occupation/match-by-education-id/${id}`,
        params: {
          limit: 100,
        },
      }),
    }),
    getOccupation: build.query<Occupation, string>({
      query: (id) => ({
        url: `/prognosportal/occupation/enrichedoccupation/${id}`,
        params: {
          includeMetadata: true,
        },
      }),
    }),
    getOccupationStatistics: build.query<OccupationStatistics, string>({
      query: (id) => ({
        url: `/prognosportal/occupation/statistics/${id}`,
      }),
    }),
  }),
})

// Define the interface/type for the search state
export interface OccupationSlice {
  query: string
}

// Set the initial state with proper types
const initialState: OccupationSlice = {
  query: '',
}

export const occupationSlice = createSlice({
  name: 'occupation',
  initialState,
  reducers: {
    setGetJobTitlesQuery: (state, action: PayloadAction<string>) => {
      state.query = action.payload
    },
  },
})

// Export the action to update the search query
export const { setGetJobTitlesQuery } = occupationSlice.actions

export const {
  useGetJobTitlesQuery,
  useMatchByTextQuery,
  useMatchByEducationIdQuery,
  useGetOccupationQuery,
  useGetOccupationStatisticsQuery,
} = occupationApi
