import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { PayloadAction, createSlice } from '@reduxjs/toolkit'

import getConfig from '../Config/configService'
import { ICompetence } from '../../types/ICompetence'

import { prepareHeaders } from './prepareHeaders'

const { API_URL } = getConfig()

export const competenceApi = createApi({
  reducerPath: 'competence-api',
  baseQuery: fetchBaseQuery({
    baseUrl: `${API_URL}kompetens/prognosportal/competence`,
    prepareHeaders,
  }),
  refetchOnMountOrArgChange: true,
  tagTypes: ['Competences'],

  endpoints: (build) => ({
    getCompetences: build.query<ICompetence[], string>({
      providesTags: ['Competences'],
      query: (searchTerm) => `/${searchTerm}`,
    }),
  }),
})

export const competenceSlice = createSlice({
  name: 'competences',
  initialState: {
    list: [] as unknown as ICompetence[],
    selectedCompetences: [] as unknown as ICompetence[],
  },
  reducers: {
    addSelectedCompetence: (state, action: PayloadAction<ICompetence>) => {
      if (state.selectedCompetences.includes(action.payload)) return
      state.selectedCompetences = [...state.selectedCompetences, action.payload]
    },
    deleteSelectedCompetence: (state, action: PayloadAction<ICompetence>) => {
      state.selectedCompetences = state.selectedCompetences.filter(
        (f) => f.term !== action.payload.term
      )
    },
    deleteAllSelectedCompetences: (state) => {
      state.selectedCompetences = []
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      competenceApi.endpoints.getCompetences.matchFulfilled,
      (_state, action: { payload: ICompetence[] }) => {
        return { ..._state, list: action.payload }
      }
    )
  },
})

export const {
  addSelectedCompetence,
  deleteAllSelectedCompetences,
  deleteSelectedCompetence,
} = competenceSlice.actions

export const { useGetCompetencesQuery } = competenceApi
