import { Typography, useTheme } from '@mui/material'
import React from 'react'

interface ISourceMetaTextProps {
  text?: string
}

const SourceMetaText: React.FC<ISourceMetaTextProps> = ({ text }) => {
  const { palette } = useTheme()
  if (!text) return null
  return (
    <Typography sx={{ mt: 2 }} color={palette.grey[700]} variant="body2">
      {text}
    </Typography>
  )
}

export default SourceMetaText
