/* eslint-disable @typescript-eslint/restrict-template-expressions */
import { Box, Stack, Typography, useTheme } from '@mui/material'
import React from 'react'
import { useEpiContent } from '@trr/app-shell-data'

import { Content } from '../../../../App/App.types'
import { IOccupationStatistics } from '../../types/IOccupationStatistics.types'

interface IOccupationBarChartProps {
  outlook?: IOccupationStatistics
}

const OccupationBarChart: React.FC<IOccupationBarChartProps> = ({
  outlook,
}) => {
  const {
    yrke: { men, women, yrkesverksammaSverige, ca },
  } = useEpiContent<Content>()
  const { palette } = useTheme()

  if (!outlook) return null

  const gender = [
    {
      label: men,
      percent: Math.round((outlook.men / (outlook.men + outlook.women)) * 100),
      amount: outlook.men,
    },
    {
      label: women,
      percent: Math.round(
        (outlook.women / (outlook.men + outlook.women)) * 100
      ),
      amount: outlook.women,
    },
  ].sort((a, b) => (a.amount ?? Infinity) - (b.amount ?? Infinity))

  return (
    <Stack
      role="figure"
      aria-labelledby="occupation-barchart-caption"
      sx={{
        backgroundColor: palette.info.main,
        color: 'white',
        m: 0,
        mt: 2,
        p: 2,
      }}
    >
      <Typography
        sx={{ mb: -1.6 }}
        variant="h1"
        component="p"
        gutterBottom={false}
      >
        {(outlook.men + outlook.women)?.toLocaleString('sv-SE')}
      </Typography>
      <Typography variant="subtitle2" component="p">
        {yrkesverksammaSverige} {outlook.year}
      </Typography>
      <Stack direction="row" sx={{ height: '40px', mt: 2 }}>
        <Box
          sx={{
            backgroundColor: palette.info.dark,
            height: '100%',
            width: gender[0].percent && `${gender[0].percent}%`,
          }}
        />
        <Box
          sx={{
            backgroundColor: palette.info.light,
            height: '100%',
            flexGrow: '1',
          }}
        />
      </Stack>

      <Stack direction="row" justifyContent="space-between">
        <Box>
          <Typography variant="h3" component="p" gutterBottom={false}>
            {`${ca} ${gender[0].percent}%`}
          </Typography>
          {gender[0].amount && (
            <Typography variant="subtitle2" component="p">
              {gender[0].amount
                ? `${gender[0].amount.toLocaleString('sv-SE')} ${gender[0].label}`
                : ''}
            </Typography>
          )}
        </Box>
        <Box sx={{ textAlign: 'right' }}>
          <Typography variant="h3" component="p" gutterBottom={false}>
            {`${ca}  ${gender[1].percent}%`}
          </Typography>
          {gender[1].amount && (
            <Typography variant="subtitle2" component="p">
              {gender[1].amount
                ? `${gender[1].amount.toLocaleString('sv-SE')} ${gender[1].label}`
                : ''}
            </Typography>
          )}
        </Box>
      </Stack>
    </Stack>
  )
}

export default OccupationBarChart
