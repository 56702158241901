import { ChartStatistics } from 'src/features/Yrke/types/OccupationStatistics.types'

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const convertToStatistics = <T extends Record<string, any>>(
  data: T,
  labelsAndColors: { label: string; color: string; key: string }[]
): ChartStatistics[] => {
  return labelsAndColors.map((item) => ({
    label: item.label,
    percent: parseFloat(data[item.key as keyof T] as string),
    color: item.color,
  }))
}

export default convertToStatistics
