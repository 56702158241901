import {
  Autocomplete,
  CircularProgress,
  Stack,
  TextField,
  Typography,
  createFilterOptions,
  useTheme,
} from '@mui/material'
import { equals } from 'ramda'
import React, { SyntheticEvent, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useEpiContent } from '@trr/app-shell-data'

import { Content } from '../../App/App.types'
import { useDebounce } from '../../utils/Hooks/useDebounce'
import { IApplicationState } from '../../utils/Store/configureStore'
import { useGetCompetencesQuery } from '../../utils/Network/competenceApi'
import { ICompetence } from '../../types/ICompetence'
import { capitalize } from '../../utils/Helpers/capitalize'

interface ISkillInputProps {
  onCompetenceSelect: (v: ICompetence) => void
}
const SkillInput: React.FC<ISkillInputProps> = ({ onCompetenceSelect }) => {
  const [open, setOpen] = useState(false)
  const [options, setOptions] = useState<ICompetence[]>([])
  const [searchInput, setSearchInput] = useState<string>('')
  const [searchParam, setSearchParam] = useState<string>('')
  const selectedCompetences = useSelector(
    (state: IApplicationState) => state.competence.selectedCompetences
  )
  const {
    kompetenser: { noOpt, addCompetence, minTva },
  } = useEpiContent<Content>()
  const { isFetching, data = [] as ICompetence[] } = useGetCompetencesQuery(
    searchParam,
    {
      skip: !searchParam,
    }
  )

  useEffect(() => {
    if (!equals(data, options)) {
      setOptions(data)
    }
  }, [data, options])
  const reset = () => {
    setOptions([])
    setSearchParam('')
  }
  useDebounce(
    () => {
      if (searchInput) setSearchParam(searchInput.toLowerCase())
      else reset()
    },
    [searchInput],
    400
  )

  const { palette } = useTheme()
  const filterOptions = createFilterOptions({
    matchFrom: 'start',
    stringify: (option: ICompetence) => option.term,
  })
  return (
    <Autocomplete
      filterOptions={filterOptions}
      fullWidth
      id="asynchronous-demo"
      sx={{ backgroundColor: 'white' }}
      loadingText={'Hämtar...'}
      noOptionsText={noOpt}
      inputValue={searchInput}
      open={open}
      onOpen={() => {
        setOpen(true)
      }}
      onClose={() => {
        setOpen(false)
      }}
      onChange={(
        event: SyntheticEvent<Element, Event>,
        newValue: ICompetence
      ) => {
        onCompetenceSelect(newValue)
        setSearchInput('')
      }}
      isOptionEqualToValue={(option, value) => option.term === value.term}
      getOptionLabel={(option) => option.term}
      options={options}
      loading={isFetching}
      renderOption={(props, option) => {
        return (
          <li {...props} key={option.term}>
            <Stack>
              <Typography sx={{ color: palette.text.disabled }} variant="body2">
                {capitalize(option.term)}
              </Typography>
              <Typography variant="body1">{capitalize(option.term)}</Typography>
            </Stack>
          </li>
        )
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          label={selectedCompetences.length > 0 ? addCompetence : minTva}
          type="search"
          onChange={(e) => setSearchInput(e.target.value)}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <React.Fragment>
                {isFetching ? (
                  <CircularProgress color="inherit" size={20} />
                ) : null}
                {params.InputProps.endAdornment}
              </React.Fragment>
            ),
          }}
        />
      )}
    />
  )
}

export default SkillInput
