import {
  Box,
  Typography,
  useTheme,
  Button,
  Card,
  CardHeader,
  CardContent,
  List,
  ListItem,
  ListItemText,
  ListItemAvatar,
  Avatar,
  Alert,
  Stack,
} from '@mui/material'
import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { RouteComponentProps } from 'react-router-dom'
import {
  useEpiContent,
  useEpiCustomDictionary,
  useIsFeatureEnabled,
} from '@trr/app-shell-data'

import { ICompetence } from '../../types/ICompetence'
import { Content } from '../../App/App.types'
import WysiwygEditor from '../../Components/WysiwygEditor'
import EducationsList from '../Utbildning/components/EducationsList/EducationsList'
import PageWrapper from '../../Components/PageWrapper/PageWrapper'
import { usePostMatchByOccupationIdQuery } from '../../utils/Network/educationsApi'
import {
  useGetOccupationQuery,
  useGetOccupationStatisticsQuery,
} from '../../utils/Network/occupationApi'
import { IApplicationState } from '../../utils/Store/configureStore'
import {
  useGetOccupationOutlooksQuery,
  useGetSalaryStatisticsQuery,
} from '../../utils/Network/referenceDataApi'
import { ISalaryStatistics } from '../../types/ISalaryStatistics.types'
import EmptyResult from '../../Components/EmptyResult/EmptyResult'

import YrkeSkeleton from './components/YrkeSkeleton/YrkeSkeleton'
import SalaryBarChart from './components/SalaryBarChart/SalaryBarChart'
import Outlook from './components/Outlook/Outlook'
import SourceMetaText from './components/SourceMetaText/SourceMetaText'
import OutlookEmptyState from './components/OutlookEmptyState/OutlookEmptyState'
import OccupationBarChart from './components/OccupationBarChart/OccupationBarChart'
import { IOccupationStatistics } from './types/IOccupationStatistics.types'
import { IOccupation } from './types/IOccupation.types'

interface IMatchParams {
  id: string
}
interface IYrkeProps extends RouteComponentProps<IMatchParams> {
  id: string
}

const Yrke: React.FC<IYrkeProps> = ({ match }) => {
  const { palette } = useTheme()
  const [showAllSkills, setShowAllSkills] = useState(false)
  const displayOccupationalOutlook = useIsFeatureEnabled(
    'Prognosportalen-frontend_occupationOutlook_perm_240619'
  )

  const {
    yrke: {
      errorFetchYrke,
      lonelage,
      kompetenser,
      rankning,
      kompetenslista,
      visaMindre,
      visaMer,
      errorFetchUtb,
      utbildningar,
      tomtResultat,
      tomtResultatUtb,
      nulage,
    },
    kallor: { kallaAF, kallaSCB },
  } = useEpiContent<Content>()

  const customDictionary = useEpiCustomDictionary(['yrke'])

  const salaryIngress = (
    salaryStatistics: ISalaryStatistics,
    occupationLabel: string
  ) =>
    customDictionary('salaryDescription', {
      minTio: salaryStatistics.percentile10.toLocaleString(),
      maxTio: salaryStatistics.percentile90.toLocaleString(),
      ar: salaryStatistics.year.toString(),
      yrke: occupationLabel,
      median: salaryStatistics.median.toString(),
    })

  const occupationStatisticsIngress = (
    occupationStatistics: IOccupationStatistics,
    occupationGroupLabel: string,
    occupationLabel: string
  ) =>
    customDictionary('occupationStatisticsIngress', {
      total: (
        occupationStatistics.men + occupationStatistics.women
      ).toLocaleString(),
      men: occupationStatistics.men.toLocaleString(),
      women: occupationStatistics.women.toLocaleString(),
      ar: occupationStatistics.year.toString(),
      yrkesgrupp: occupationGroupLabel,
      yrke: occupationLabel,
    })

  const {
    data = {} as IOccupation,
    isFetching: isFetchingOccupation,
    isError: isErrorFetchingOccupation,
  } = useGetOccupationQuery(match.params.id)

  const {
    isFetching: isFetchingEducations,
    isError: isErrorFetchingEducations,
  } = usePostMatchByOccupationIdQuery(match.params.id)

  const educationsData = useSelector(
    (state: IApplicationState) => state.educations.matchByOccupationList
  )

  const { isFetching: isFetchingOutlooks } = useGetOccupationOutlooksQuery(
    {
      ssyk: data.occupationGroup?.ssyk ?? '',
      occupationId: data.id,
    },
    {
      skip: !data.occupationGroup?.ssyk,
    }
  )

  const { isFetching: isFetchingSalaryStatistics } =
    useGetSalaryStatisticsQuery(data.occupationGroup?.ssyk ?? '', {
      skip: !data.occupationGroup?.ssyk,
    })

  const { isFetching: isFetchingOccupationStatistics } =
    useGetOccupationStatisticsQuery(data.occupationGroup?.ssyk ?? '', {
      skip: !data.occupationGroup?.ssyk,
    })

  const occupationalOutlook =
    useSelector(
      (state: IApplicationState) =>
        state.referenceData.occupationalOutlook[data.id ?? '']
    ) ?? null

  const salaryStatistics = useSelector(
    (state: IApplicationState) =>
      state.referenceData.salaryStatistics[data.occupationGroup?.ssyk ?? '']
  )

  const { data: occupationStatistics } = useGetOccupationStatisticsQuery(
    data.occupationGroup?.ssyk ?? '',
    { skip: !data.occupationGroup?.ssyk }
  )

  const filteredSkills =
    data?.competences?.filter(
      (c) => c.percentForOccupation && c.percentForOccupation > 0.06
    ) ?? []
  const skills = showAllSkills ? filteredSkills : filteredSkills.slice(0, 15)

  const skillChunks: ICompetence[][] = skills.reduce<ICompetence[][]>(
    (resultArray, item, index) => {
      const chunkIndex = Math.floor(index / 15)
      if (!resultArray[chunkIndex]) {
        resultArray[chunkIndex] = [] as ICompetence[]
      }
      resultArray[chunkIndex].push(item)
      return resultArray
    },
    []
  )

  if (
    isFetchingOccupation ||
    isFetchingOutlooks ||
    isFetchingSalaryStatistics ||
    isFetchingOccupationStatistics
  )
    return <YrkeSkeleton />
  return (
    <>
      <PageWrapper
        isLoading={isFetchingOccupation}
        errorText={errorFetchYrke}
        errorLoadingData={isErrorFetchingOccupation}
      >
        {!displayOccupationalOutlook && (
          <Box mb={4}>
            <Alert severity="warning">
              Vi håller på att förbättra tjänsten. Under tiden kan vi inte visa
              yrkesprognoser. Tack för din förståelse.
            </Alert>
          </Box>
        )}

        <Typography
          variant="h1"
          mb={1}
          sx={{ wordBreak: 'break-word', overflowWrap: 'break-word' }}
        >
          {data.occupationLabel}
        </Typography>
        <Typography gutterBottom variant="body1" mb={4}>
          {data.description}
        </Typography>

        {occupationalOutlook?.outlookValue && displayOccupationalOutlook ? (
          <Outlook occupationOutlook={occupationalOutlook} />
        ) : (
          <OutlookEmptyState />
        )}

        {occupationStatistics && (
          <Stack sx={{ mt: 4, backgroundColor: palette.surface?.blue }}>
            <Box sx={{ padding: { xs: 2, sm: 5 } }}>
              <Typography gutterBottom variant="h3" component="h2">
                {nulage}
              </Typography>
              <Typography
                gutterBottom
                variant="body1"
                id="occupation-barchart-caption"
              >
                {occupationStatisticsIngress(
                  occupationStatistics,
                  data.occupationGroup?.occupationGroupLabel || '',
                  data.occupationLabel
                )}
              </Typography>
              <OccupationBarChart outlook={occupationStatistics} />
              <SourceMetaText
                text={`${kallaSCB} (${occupationStatistics?.year})`}
              />
            </Box>
          </Stack>
        )}

        {salaryStatistics && (
          <Box
            sx={{
              padding: { xs: 2, sm: 5 },
              mt: 4,
              backgroundColor: palette.surface?.blue,
            }}
          >
            <Typography gutterBottom variant="h3" component="h2">
              {lonelage}
            </Typography>
            <Typography
              gutterBottom
              variant="body1"
              id="salary-barchart-caption"
            >
              {salaryIngress(salaryStatistics, data.occupationLabel)}
            </Typography>
            <SalaryBarChart statistics={salaryStatistics} />
            <SourceMetaText text={`${kallaSCB} (${salaryStatistics.year})`} />
          </Box>
        )}

        {skills.length > 0 && (
          <Box
            sx={{ p: { xs: 2, sm: 5 }, mt: 4, mb: 5 }}
            bgcolor={palette.surface?.blue}
          >
            <Typography gutterBottom variant="h3" component="h2">
              {kompetenser}
            </Typography>
            <WysiwygEditor
              styles={{ fontSize: '16px', mb: 3 }}
              htmlFormattedString={customDictionary('description', {
                noOfSkills: filteredSkills.length.toString(),
                yrkesgrupp: data.occupationGroup?.occupationGroupLabel ?? '',
                yrke: data.occupationLabel,
              })}
            />
            <Card>
              <CardHeader
                sx={{ p: 3, pb: 0 }}
                title={rankning}
                titleTypographyProps={{ component: 'h3' }}
                subheader={kompetenslista}
                subheaderTypographyProps={{ component: 'p' }}
              />
              <CardContent sx={{ p: 3, pt: 1 }}>
                {skillChunks?.map((ch, i) => (
                  <List
                    key={i}
                    component="ol"
                    sx={{ display: 'flex', flexFlow: 'row wrap' }}
                  >
                    {ch?.map((s, j) => (
                      <ListItem
                        key={s.term}
                        sx={{
                          paddingBottom: {
                            lg: 1,
                            xs: `${j % 5 === 4 ? 24 : 8}px`,
                          },
                          width: { lg: '33%' },
                          border: 'none',
                          paddingLeft: '0',
                        }}
                      >
                        <ListItemAvatar>
                          <Avatar
                            sx={{
                              backgroundColor: palette.surface?.grey,
                              color: palette.text.primary,
                            }}
                          >
                            <Typography variant="subtitle1" component="span">
                              {' '}
                              {i * 15 + j + 1}
                            </Typography>
                          </Avatar>
                        </ListItemAvatar>
                        <ListItemText> {s.term}</ListItemText>
                      </ListItem>
                    ))}
                  </List>
                ))}

                <br />
                <Button
                  size="medium"
                  color="primary"
                  sx={{ mr: 2, mb: 1 }}
                  onClick={() => setShowAllSkills(!showAllSkills)}
                >
                  {showAllSkills ? visaMindre : visaMer}
                </Button>
              </CardContent>
            </Card>
            <SourceMetaText text={kallaAF} />
          </Box>
        )}

        <PageWrapper
          isLoading={isFetchingEducations}
          errorText={errorFetchUtb}
          errorLoadingData={isErrorFetchingEducations}
        >
          {educationsData && (
            <EducationsList
              itemHeaderComponent="h3"
              showTypeToggle={false}
              header={utbildningar}
              educations={educationsData.educations ?? []}
              noResult={
                <EmptyResult
                  heading={tomtResultat}
                  description={tomtResultatUtb}
                />
              }
            />
          )}
        </PageWrapper>
      </PageWrapper>
    </>
  )
}

export default Yrke
