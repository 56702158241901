import React, { useEffect, useState } from 'react'
import { Typography, Breadcrumbs, Link } from '@mui/material'
import { RouteComponentProps, withRouter, matchPath } from 'react-router-dom'
import { useSelector } from 'react-redux'

import { getAncestorLinks } from '../../utils/Helpers/getAncestorLinks'
import { capitalize } from '../../utils/Helpers/capitalize'
import { buildUrl } from '../../utils/Helpers/buildUrl'
import { IApplicationState } from '../../utils/Store/configureStore'

import ArrowRightIcon from './ArrowRightIcon'
import { IOccupation } from 'src/Features/Yrke/types/IOccupation.types'
import { useGetOccupationQuery } from 'src/utils/Network/occupationApi'
import { IEducation } from 'src/Features/Utbildning/types/IEducation.types'
import { useGetEducationQuery } from 'src/utils/Network/educationsApi'

// eslint-disable-next-line @typescript-eslint/no-explicit-any
interface IBreadcrumbsProps extends RouteComponentProps<any> {
  basename: string
}

const BreadcrumbStart: React.FC<IBreadcrumbsProps> = ({
  basename,
  history,
}) => {
  const [ancestors, setAncestors] = useState(
    getAncestorLinks(basename + history.location.pathname)
  )

  const match = matchPath(history.location.pathname, {
    path: '/*/:id',
    exact: true,
    strict: false,
  })
  const matchParams = match?.params as { id: string }

  useEffect(() => {
    const unlisten = history.listen(() => {
      setAncestors(getAncestorLinks(basename + history.location.pathname))
    })
    return () => {
      unlisten()
    }
  }, [])

  const isDisplayingOccupation =
    ancestors[ancestors.length - 2]?.label === 'yrken'
  const isDisplayingEducation =
    ancestors[ancestors.length - 2]?.label === 'utbildningar'
  const isDisplayingReport =
    ancestors[ancestors.length - 2]?.label === 'rapporter'
  const isDisplayingHome =
    ancestors[ancestors.length - 1]?.label === 'prognosportalen'

  const { data: occupation = {} as IOccupation } = useGetOccupationQuery(
    matchParams?.id,
    { skip: !isDisplayingOccupation || !matchParams?.id }
  )

  const { data: education = {} as IEducation } = useGetEducationQuery(
    matchParams?.id.replaceAll('-', '.'),
    { skip: !isDisplayingEducation || !matchParams?.id }
  )

  const educationLabel = education?.title

  const reportTitle = useSelector(
    (state: IApplicationState) => state.industryReport.title
  )

  if (isDisplayingOccupation) {
    ancestors.pop()
    ancestors.push({
      label: occupation?.occupationLabel,
      path: occupation?.occupationLabel,
    })
  }
  if (isDisplayingEducation) {
    ancestors.pop()
    ancestors.push({ label: educationLabel ?? '', path: educationLabel ?? '' })
  }
  if (isDisplayingReport) {
    ancestors.pop()
    ancestors.push({ label: reportTitle, path: reportTitle })
  }
  if (isDisplayingHome) {
    ancestors.push({ label: 'Utbildningar', path: 'Utbildningar' })
  }

  if (ancestors.length > 0)
    return (
      <Breadcrumbs
        aria-label="Breadcrumb"
        sx={{ mb: 3 }}
        separator={<ArrowRightIcon />}
      >
        {ancestors.map((a, i) =>
          i === ancestors.length - 1 ? (
            <Typography
              key={i}
              variant="subtitle1"
              component="span"
              color="#1a1a1a"
              fontSize={{ sm: '15px', xs: '14px' }}
            >
              {' '}
              {capitalize(a?.label)}
            </Typography>
          ) : (
            <Link
              key={i}
              variant="subtitle1"
              component="a"
              href={`${buildUrl(
                ancestors.map((el) => el?.path),
                i + 1
              )}`}
              fontSize={{ sm: '15px', xs: '14px' }}
            >
              {capitalize(a?.label)}
            </Link>
          )
        )}
      </Breadcrumbs>
    )
  else return null
}

export default withRouter(BreadcrumbStart)
