import { County } from 'src/types/County'
import { Location, LocationType } from 'src/types/Location'
import { Municipality } from 'src/types/Municipality.types'
import referenceDataApi from '../referenceDataApi'

export const locationsApi = referenceDataApi.injectEndpoints({
  endpoints: (build) => ({
    getMunicipalities: build.query<Location[], void>({
      query: () => ({ url: `/locations/municipalities` }),
      transformResponse: (response: Municipality[]): Location[] =>
        response.map((el) => ({
          id: el.countyAndMunicipalityCode,
          name: el.municipality,
          type: LocationType.municipality,
        })),
    }),
    getCounties: build.query<Location[], void>({
      query: () => ({ url: `/locations/counties` }),
      transformResponse: (response: County[]): Location[] =>
        response.map((el) => ({
          id: el.countyCode,
          name: el.county,
          type: LocationType.county,
        })),
    }),
  }),
})

export const { useGetMunicipalitiesQuery, useGetCountiesQuery } = locationsApi
