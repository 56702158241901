import { Box, Typography, Stack, useMediaQuery, useTheme } from '@mui/material'
import React from 'react'
import { Content } from 'src/App/App.types'
import WysiwygEditor from 'src/Components/WysiwygEditor'
// eslint-disable-next-line import/no-extraneous-dependencies
import { useEpiContent } from '@trr/app-shell-data'

import EpiImage from '../../Components/EpiImage/EpiImage'

// eslint-disable-next-line @typescript-eslint/consistent-type-definitions
type IKompetenserStartContent = {
  bildaldremanstuderar: {
    url: string
  }
  kompetensMainBody: {
    mainBody: string
  }
}

const KompetenserStart = () => {
  const { bildaldremanstuderar, kompetensMainBody } =
    useEpiContent<IKompetenserStartContent>()
  const {
    kompetenser: { heading },
  } = useEpiContent<Content>()
  const theme = useTheme()

  return (
    <Stack direction="row" sx={{ mt: 4, backgroundColor: 'surface.orange' }}>
      <Box sx={{ padding: { xs: 2, sm: 5 } }}>
        <Typography
          gutterBottom
          variant="h2"
          sx={{ wordBreak: 'break-word', overflowWrap: 'break-word' }}
        >
          {heading}
        </Typography>
        {kompetensMainBody && (
          <WysiwygEditor
            htmlFormattedString={kompetensMainBody.mainBody}
            styles={{
              fontSize: '16px',
              pt: 3,
              pb: 3,
            }}
          />
        )}
      </Box>
      {useMediaQuery(theme.breakpoints.up('md')) && bildaldremanstuderar && (
        <EpiImage width={'400px'} url={bildaldremanstuderar?.url} />
      )}
    </Stack>
  )
}

export default KompetenserStart
