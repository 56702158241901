import utbildningar from './utbildningar.json'
import utbildning from './utbildning.json'
import kallor from './kallor.json'
import palitlighetsblock from './palitlighetsblock.json'
import yrke from './yrke.json'
import yrken from './yrken.json'
import scbStatistik from './scbStatistik.json'
import filtrering from './filtrering.json'
import kompetenser from './kompetenser.json'
import rapporter from './rapporter.json'
import rapport from './rapport.json'

export const translation = {
  utbildningar,
  utbildning,
  yrken,
  yrke,
  kompetenser,
  rapporter,
  rapport,
  kallor,
  palitlighetsblock,
  scbStatistik,
  filtrering,
}
const resources = {
  translation,
} as const

export type ResourceContent = typeof translation

export default resources
