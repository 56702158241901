import React from 'react'
import { Provider } from 'react-redux'
import { createTheme, ThemeProvider } from '@mui/material'
import { BrowserRouter, Route, Switch } from 'react-router-dom'
import { defaultLocale, themeOptions } from '@trr/mui-theme'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { sv } from 'date-fns/locale'
import { LocalizationProvider } from '@mui/x-date-pickers'
import { AppShellDataProvider, AppShellDataProps } from '@trr/app-shell-data'
import { GTMTracker } from '@trr/gtm-tracking'

import store from '../utils/Store/configureStore'
import Tabs from '../Features/Tabs/Tabs'
import { IRoute } from '../types/IRoute.types'
import Yrkesroller from '../Features/Yrkesroller/Yrkesroller'
import MainContainer from '../Components/MainContainer/MainContainer'
import Kompetenser from '../Features/Kompetenser/Kompetenser'
import Utbildningar from '../Features/Utbildning/Utbildningar'
import Utbildning from '../Features/Utbildning/Utbildning'
import Yrke from '../Features/Yrke/Yrke'
import ScrollToTop from '../Components/ScrollToTop/ScrollToTop'
import BreadcrumbStart from '../Components/Breadcrumbs/BreadcrumbStart'
import Rapporter from '../Features/Rapporter/Rapporter'
import Rapport from '../Features/Rapporter/Rapport'

const tabRoutes: IRoute[] = [
  {
    label: 'Utbildningar',
    href: 'utbildningar',
    component: <Utbildningar />,
  },
  {
    label: 'Kompetenser',
    href: 'kompetenser',
    component: <Kompetenser />,
  },
  {
    label: 'Yrken',
    href: 'yrken',
    component: <Yrkesroller />,
  },
  {
    label: 'Rapporter',
    href: 'rapporter',
    component: <Rapporter />,
  },
]

const App = ({ appShellData }: { appShellData: AppShellDataProps }) => {
  const theme = createTheme(themeOptions, defaultLocale)
  const baseName = '/mitt-trr/karriarvagledning/yrkesprognoser/prognosportalen/'

  return (
    <ThemeProvider theme={theme}>
      <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={sv}>
        <Provider store={store}>
          <GTMTracker mfName="prognosportalen">
            <AppShellDataProvider value={appShellData}>
              <MainContainer>
                <BrowserRouter basename={baseName}>
                  <BreadcrumbStart basename={baseName} />
                  <ScrollToTop>
                    <Switch>
                      <Route
                        exact
                        path="/utbildningar/:id"
                        component={Utbildning}
                      />
                      <Route exact path="/yrken/:id" component={Yrke} />
                      <Route exact path="/rapporter/:id" component={Rapport} />
                      <Tabs routes={tabRoutes} />
                    </Switch>
                  </ScrollToTop>
                </BrowserRouter>
              </MainContainer>
            </AppShellDataProvider>
          </GTMTracker>
        </Provider>
      </LocalizationProvider>
    </ThemeProvider>
  )
}

export default App
