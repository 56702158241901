import { Skeleton, SkeletonProps } from '@mui/material'
import React, { useEffect, useState } from 'react'

interface DelayedSkeletonProps extends SkeletonProps {
  delay?: number
}

const DelayedSkeleton: React.FC<DelayedSkeletonProps> = ({
  delay = 500,
  ...props
}) => {
  const [hide, setHide] = useState<boolean>(true)

  useEffect(() => {
    if (hide) setTimeout(() => setHide(false), delay)
  }, [hide, delay])

  if (hide) return null
  return <Skeleton data-testid="loading-skeleton" {...props} />
}

export default DelayedSkeleton
