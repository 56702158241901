import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { equals, reject } from 'ramda'
import { Filter } from 'src/types/Filter.types'

import { Location } from 'src/types/Location'

export interface EducationFilters {
  educationForms?: Filter[]
  educationTypes?: Filter[]
  studyPace?: Filter
  other?: Filter[]
}

export interface EducationFilterState {
  lastUpdated: number
  page: number
  filters: EducationFilters
  educationSearch: string
  location: Location
}

const initialState: EducationFilterState = {
  lastUpdated: 0,
  page: 0,
  educationSearch: '',
  location: {
    id: '',
    name: '',
  },
  filters: {
    educationForms: [] as Filter[],
    educationTypes: [{ name: 'Program', id: 'program' }] as Filter[],
    studyPace: {} as Filter,
    other: [] as Filter[],
  },
}

const removeDeep = (filters: EducationFilters, filter: Filter) => {
  const newFilters = { ...filters }

  newFilters.educationForms = newFilters?.educationForms?.filter(
    (e) => !equals(filter, e)
  )
  newFilters.educationTypes = newFilters?.educationTypes?.filter(
    (e) => !equals(filter, e)
  )
  newFilters.other = newFilters?.other?.filter((e) => !equals(filter, e))
  newFilters.studyPace = {} as Filter

  return newFilters
}

export const educationFilterSlice = createSlice({
  name: 'educationFilter',
  initialState,
  reducers: {
    updateFilters: (state, action: PayloadAction<EducationFilters>) => {
      state.lastUpdated = new Date().getTime()
      state.filters = { ...state.filters, ...action.payload }
      state.page = 0
    },
    deleteFilter: (state, action: PayloadAction<Filter>) => {
      state.lastUpdated = new Date().getTime()
      state.filters = { ...removeDeep(state.filters, action.payload) }
      state.page = 0
    },
    deleteAllFilters: (state) => {
      state.lastUpdated = new Date().getTime()
      state.filters = initialState.filters
      state.page = 0
    },
    updatePage: (state, action: PayloadAction<number>) => {
      state.page = action.payload
    },
    updateLocation: (state, action: PayloadAction<Location>) => {
      state.location = action.payload
      state.page = 0
    },
    updateEducationSearch: (state, action: PayloadAction<string>) => {
      state.educationSearch = action.payload
      state.page = 0
    },
    addEducationTypeFilter: (state, action: PayloadAction<Filter>) => {
      state.filters.educationTypes = state.filters.educationTypes
        ? [...state.filters.educationTypes, action.payload]
        : []
    },
    removeEducationTypeFilter: (state, action: PayloadAction<Filter>) => {
      const newArr = state.filters.educationTypes
        ? [...state.filters.educationTypes]
        : []
      state.filters.educationTypes = reject(
        (r: Filter) => r.name === action.payload.name,
        newArr
      )
    },
  },
})

export const {
  updateFilters,
  deleteFilter,
  deleteAllFilters,
  updatePage,
  updateLocation,
  updateEducationSearch,
  addEducationTypeFilter,
  removeEducationTypeFilter,
} = educationFilterSlice.actions
