import { Box, Typography, Stack, useMediaQuery, useTheme } from '@mui/material'
import React from 'react'
import { useEpiContent } from '@trr/app-shell-data'
import { Content } from 'src/App/App.types'
import WysiwygEditor from 'src/Components/WysiwygEditor'

import EpiImage from '../../Components/EpiImage/EpiImage'

// eslint-disable-next-line @typescript-eslint/consistent-type-definitions
type IUtbildningarContent = {
  bildkvinnaforelaser: {
    url: string
  }
  utbildningMainBody: {
    mainBody: string
  }
}

const UtbildningarStart = () => {
  const { bildkvinnaforelaser, utbildningMainBody } =
    useEpiContent<IUtbildningarContent>()
  const {
    utbildningar: { heading },
  } = useEpiContent<Content>()

  const theme = useTheme()
  return (
    <Stack direction="row" sx={{ mt: 4, backgroundColor: 'surface.orange' }}>
      <Box sx={{ padding: { sm: 5, xs: 2 } }}>
        <Typography
          gutterBottom
          variant="h4"
          component="h2"
          sx={{ wordBreak: 'break-word', overflowWrap: 'break-word' }}
        >
          {heading}
        </Typography>
        {utbildningMainBody && (
          <WysiwygEditor
            htmlFormattedString={utbildningMainBody.mainBody}
            styles={{
              fontSize: '16px',
              pt: 3,
              pb: 3,
            }}
          />
        )}
      </Box>
      {useMediaQuery(theme.breakpoints.up('md')) && bildkvinnaforelaser && (
        <EpiImage width={'400px'} url={bildkvinnaforelaser.url} />
      )}
    </Stack>
  )
}

export default UtbildningarStart
