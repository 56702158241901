/* eslint-disable import/no-extraneous-dependencies */
import React, { useCallback, useEffect, useState } from 'react'
import {
  Box,
  Stack,
  TextField,
  Typography,
  Pagination,
  debounce,
  CircularProgress,
  InputAdornment,
} from '@mui/material'
import SearchIcon from '@mui/icons-material/Search'
import { TransitionGroup } from 'react-transition-group'
import { useEpiContent } from '@trr/app-shell-data'

import { useGetAllCountiesQuery } from '../../utils/Network/referenceDataApi'
import { Content } from '../../App/App.types'
import {
  useGetIndustryReportsQuery,
  useGetOccupationGroupsQuery,
} from '../../utils/Network/industryReportApi'
import ArticleCard from './ArticleCard'
import PageWrapper from '../../Components/PageWrapper/PageWrapper'
import EmptyResult from '../../Components/EmptyResult/EmptyResult'
import Filters from './Filters/Filters'
import { FilterType } from './Filters/Filter/Filter'

const Articles = () => {
  const {
    rapporter: {
      sok,
      errorFetchReports,
      hits,
      hit,
      tomtResultat,
      tomtResultatRapporter,
    },
    filtrering: { regioner, yrken },
  } = useEpiContent<Content>()

  const [filters, setFilters] = useState<FilterType[]>([
    {
      id: '1',
      name: 'regions',
      label: regioner,
      filters: [],
      data: [],
    },
    {
      id: '2',
      name: 'occupationGroups',
      label: yrken,
      filters: [],
      data: [],
    },
  ])

  const [searchQuery, setSearchQuery] = useState('')
  const { data: occupationGroups } = useGetOccupationGroupsQuery()
  const { data: regions } = useGetAllCountiesQuery()
  const { data: articles, isLoading } = useGetIndustryReportsQuery({
    searchQuery: searchQuery,
    regions: filters.find((el) => el.name === 'regions')?.filters,
    occupationGroups: filters.find((el) => el.name === 'occupationGroups')
      ?.filters,
  })

  const [page, setPage] = useState<number>(0)

  const searchChangeHandler = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setSearchQuery(event.target.value)
  }
  const debouncedSearchHandler = useCallback(
    debounce(searchChangeHandler, 300),
    []
  )

  const onChangeFilter = (filterId: string, value: string) => {
    const newFilters = filters.map((el) => {
      if (el.id === filterId) {
        if (!el.filters.includes(value)) {
          el.filters = [...el.filters, value]
        } else {
          el.filters = el.filters.filter((el) => el !== value)
        }
      }
      return el
    })

    setFilters(newFilters)
  }

  const onResetFilters = (filterId?: string) => {
    // Resets all filters
    if (!filterId) {
      const noFilters = filters.map((el) => {
        el.filters = []

        return el
      })
      setFilters(noFilters)
    }

    // Resets one specific filter
    const removedFilter = filters.map((el) => {
      if (el.id === filterId) {
        el.filters = []
      }
      return el
    })

    setFilters(removedFilter)
  }

  useEffect(() => {
    if (regions && regions.length > 0) {
      const fetchedData = filters.map((el) => {
        if (el.name === 'regions') {
          el.data = regions
        }
        if (el.name === 'occupationGroups') {
          el.data = occupationGroups
        }

        return el
      })

      setFilters(fetchedData)
    }
  }, [regions, occupationGroups])

  if (isLoading) return <CircularProgress />
  if (!articles) return null

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          gap: 2,
          flexDirection: {
            xs: 'column',
            sm: 'row',
          },
        }}
      >
        <TextField
          sx={{ backgroundColor: 'white' }}
          fullWidth
          id="rapporter"
          name="rapporter"
          label={sok}
          type="search"
          onChange={debouncedSearchHandler}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <SearchIcon />
              </InputAdornment>
            ),
          }}
        />
      </Box>

      <Filters
        filters={filters}
        handleSetFilters={onChangeFilter}
        handleResetFilters={onResetFilters}
      />

      <PageWrapper
        errorText={errorFetchReports}
        // errorLoadingData={isError}
        errorLoadingData={false}
      >
        {articles?.length > 0 ? (
          <>
            <TransitionGroup>
              <Stack direction="row">
                <Typography sx={{ mt: 1 }} variant="subtitle1" component="p">
                  {articles.length} {articles.length > 1 ? hits : hit}
                </Typography>
              </Stack>
              <Stack sx={{ mt: 4 }} gap={3}>
                {articles
                  ?.slice(page * 10, page * 10 + 10)
                  ?.map((r) => <ArticleCard key={r.id} values={{ ...r }} />)}
              </Stack>
            </TransitionGroup>
            {articles?.length > 10 && (
              <Stack
                sx={{ mt: 3 }}
                justifyContent="center"
                direction="row"
                spacing={2}
              >
                <Pagination
                  page={page + 1}
                  onChange={(e, v) => {
                    setPage(v - 1)
                    window.scrollTo(0, 100)
                  }}
                  count={Math.ceil(articles.length / 10)}
                />
              </Stack>
            )}
          </>
        ) : (
          <Box sx={{ mt: 4 }}>
            <EmptyResult
              heading={tomtResultat}
              description={tomtResultatRapporter}
            />
          </Box>
        )}
      </PageWrapper>
    </>
  )
}

export default Articles
