import kompetensApi from '../kompetensApi'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { Competence } from 'src/types/Competence'

export const competenceApi = kompetensApi.injectEndpoints({
  endpoints: (build) => ({
    getCompetences: build.query<Competence[], string>({
      query: (searchTerm) => ({
        url: `/prognosportal/competence/${searchTerm}`,
      }),
    }),
  }),
})

export const competenceSlice = createSlice({
  name: 'competences',
  initialState: {
    list: [] as unknown as Competence[],
    selectedCompetences: [] as unknown as Competence[],
  },
  reducers: {
    addSelectedCompetence: (state, action: PayloadAction<Competence>) => {
      if (state.selectedCompetences.includes(action.payload)) return
      state.selectedCompetences = [...state.selectedCompetences, action.payload]
    },
    deleteSelectedCompetence: (state, action: PayloadAction<Competence>) => {
      state.selectedCompetences = state.selectedCompetences.filter(
        (f) => f.term !== action.payload.term
      )
    },
    deleteAllSelectedCompetences: (state) => {
      state.selectedCompetences = []
    },
  },
})

export const {
  addSelectedCompetence,
  deleteAllSelectedCompetences,
  deleteSelectedCompetence,
} = competenceSlice.actions

export const { useGetCompetencesQuery } = competenceApi
