/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable @typescript-eslint/restrict-template-expressions */
/* eslint-disable @typescript-eslint/no-unsafe-return */
import { Money, SwapVert } from '@mui/icons-material'
import {
  Card,
  CardActionArea,
  CardContent,
  CardHeader,
  Stack,
  Typography,
} from '@mui/material'
import React from 'react'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import {
  useEpiContent,
  useEpiCustomDictionary,
  useIsFeatureEnabled,
} from '@trr/app-shell-data'

import { Content } from '../../../../App/App.types'
import { IconText } from '../../../../Components/IconText/IconText'
import {
  useGetOccupationOutlooksQuery,
  useGetSalaryStatisticsQuery,
} from '../../../../utils/Network/referenceDataApi'
import { IApplicationState } from '../../../../utils/Store/configureStore'
import SourceMetaText from '../SourceMetaText/SourceMetaText'

interface IOccupationCardProps {
  header: string
  subheader?: string
  id: string
  occupationGroupId?: string
  occupationGroupLabel?: string
  ssyk?: string
  headerComponent: string
}

const OccupationCard: React.FC<IOccupationCardProps> = ({
  id,
  header,
  subheader,
  headerComponent,
  ssyk,
}) => {
  const displayOccupationalOutlook = useIsFeatureEnabled(
    'Prognosportalen-frontend_occupationOutlook_perm_240619'
  )
  useGetOccupationOutlooksQuery(
    {
      ssyk: ssyk ?? '',
      occupationId: id,
    },
    {
      skip: !ssyk,
    }
  )

  useGetSalaryStatisticsQuery(ssyk ?? '', {
    skip: !ssyk,
  })

  const occupationalOutlook =
    useSelector(
      (state: IApplicationState) =>
        state.referenceData.occupationalOutlook[id ?? '']
    ) ?? null

  const salaryStatistics = useSelector(
    (state: IApplicationState) =>
      state.referenceData.salaryStatistics[ssyk ?? '']
  )
  const {
    yrke: {
      femArsSikt,
      oforandradEfterfragan,
      okandeEfterfragan,
      minskandeEfterfragan,
    },
    kallor: { kallaAF, kallaSCB },
    yrkeskort: { tillArbeteAr, saknarData, medellon, kr },
  } = useEpiContent<Content>()
  const customDictionary = useEpiCustomDictionary(['kallor'])
  const sourceText = () => {
    return occupationalOutlook?.year && salaryStatistics?.year
      ? customDictionary('kallor', {
          scbYear: salaryStatistics?.year.toString(),
          afYear: occupationalOutlook?.year,
        }) // Vi har data från både arbetsförmedlingen (occupationalOutlook) och scb (salaryStatistics)
      : occupationalOutlook?.year
        ? `${kallaAF} (${occupationalOutlook?.year})` // Vi har bara data från arbetsförmedlingen (occupationalOutlook)
        : salaryStatistics?.year
          ? `${kallaSCB} (${salaryStatistics?.year})`
          : '' // Vi har bara data från arbetsförmedlingen (salaryStatistics)
  }

  const HeaderComponent = ({ ...props }) =>
    React.createElement(headerComponent, props)
  const ariaLabel = `${header}, ${subheader}`
  return (
    <Card
      key={id}
      component="article"
      sx={{ marginTop: 3, backgroundColor: 'transparent' }}
    >
      <CardActionArea
        component={Link}
        to={`/yrken/${id}`}
        aria-label={ariaLabel}
        data-gtm-label={header}
      >
        <CardHeader
          title={
            <Typography
              variant="h5"
              component={HeaderComponent}
              sx={{ wordBreak: 'break-word', overflowWrap: 'break-word' }}
            >
              {header}
            </Typography>
          }
          subheader={subheader}
          subheaderTypographyProps={{ component: 'p' }}
        />
        <CardContent>
          <Stack
            sx={{ flexDirection: { sm: 'row', xs: 'column' } }}
            gap={{ xs: 1, sm: 2 }}
            marginBottom={{ xs: 1, sm: 0 }}
          >
            {occupationalOutlook?.outlookValue && displayOccupationalOutlook ? (
              <>
                <IconText
                  key={occupationalOutlook.id}
                  label={occupationalOutlook.outlookLabel}
                  subLabel={`${tillArbeteAr} ${occupationalOutlook.year}`}
                  icon={<SwapVert />}
                />
                <IconText
                  label={
                    occupationalOutlook.prognos === 'vara oförändrad'
                      ? oforandradEfterfragan
                      : occupationalOutlook.prognos === 'minska'
                        ? minskandeEfterfragan
                        : okandeEfterfragan
                  }
                  subLabel={femArsSikt}
                  icon={<SwapVert />}
                />
              </>
            ) : (
              <>
                <IconText
                  disabled
                  label={saknarData}
                  subLabel={`${tillArbeteAr} ${new Date().getFullYear()}`}
                  icon={<SwapVert />}
                />
                <IconText
                  disabled
                  label={saknarData}
                  subLabel={femArsSikt}
                  icon={<SwapVert />}
                />
              </>
            )}
            {salaryStatistics && (
              <IconText
                label={`${salaryStatistics.percentile10.toLocaleString(
                  'sv-SE'
                )} - ${salaryStatistics.percentile90.toLocaleString(
                  'sv-SE'
                )}kr/mån`}
                subLabel={`${medellon} ${salaryStatistics.median.toLocaleString(
                  'sv-SE'
                )} ${kr}`}
                icon={<Money />}
              />
            )}
          </Stack>

          <SourceMetaText text={sourceText()} />
        </CardContent>
      </CardActionArea>
    </Card>
  )
}

export default OccupationCard
