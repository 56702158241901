/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable @typescript-eslint/no-unsafe-return */
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { createSlice } from '@reduxjs/toolkit'
import queryString from 'query-string'

import getConfig from '../Config/configService'
import { IIndustryArticle } from '../../Features/Rapporter/types/IndustryArticle.type'
import { Data } from '../../Features/Rapporter/Filters/Filter/Filter'
import { OccupationGroup } from '../../types/OccupationGroup'

import { prepareHeaders } from './prepareHeaders'

const { API_URL } = getConfig()

export const industryReportApi = createApi({
  reducerPath: 'industry-report-api',
  baseQuery: fetchBaseQuery({
    baseUrl: `${API_URL}kompetens/`,
    prepareHeaders,
    paramsSerializer: (params: Record<string, unknown>) =>
      queryString.stringify(params),
  }),

  tagTypes: ['IndustryReports'],
  endpoints: (build) => ({
    getIndustryReports: build.query<
      IIndustryArticle[],
      {
        searchQuery: string
        regions?: string[]
        statuses?: string[]
        occupationGroups?: string[]
      }
    >({
      query: (arg) => ({
        url: `/industryReport`,
        params: {
          searchQuery: arg.searchQuery,
          ...(arg.regions &&
            arg.regions.length > 0 && { regions: arg.regions }),
          ...(arg.occupationGroups &&
            arg.occupationGroups.length > 0 && {
              occupationGroups: arg.occupationGroups,
            }),
        },
      }),
      transformResponse: (response: IIndustryArticle[]) => {
        return response.map((r) => {
          const publicationDate =
            r?.publicationDate && r.publicationDate !== '0001-01-01T00:00:00'
              ? r.publicationDate
              : undefined
          return { ...r, publicationDate }
        })
      },
    }),
    getIndustryReport: build.query<IIndustryArticle, string>({
      query: (id) => ({ url: `/industryReport/${id}` }),
      transformResponse: (response: IIndustryArticle) => {
        return {
          ...response,
          publicationDate:
            response?.publicationDate &&
            response.publicationDate !== '0001-01-01T00:00:00'
              ? response.publicationDate
              : undefined,
        }
      },
    }),
    getOccupationGroups: build.query<Data[], void>({
      query: () => `/IndustryReport/occupationGroups`,
      transformResponse: (response: OccupationGroup[]): Data[] =>
        response.map((el) => ({
          id: el.id,
          label: el.occupationGroupName,
        })),
    }),
  }),
})

export const industryReportSlice = createSlice({
  name: 'industryReport',
  initialState: {} as unknown as IIndustryArticle,
  reducers: {},
  extraReducers: (builder) => {
    builder.addMatcher(
      industryReportApi.endpoints.getIndustryReport.matchFulfilled,
      (_state, action: { payload: IIndustryArticle }) => {
        return action.payload
      }
    )
  },
})

export const {
  useGetIndustryReportsQuery,
  useGetIndustryReportQuery,
  useGetOccupationGroupsQuery,
} = industryReportApi
