import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'

import getConfig from '../Config/configService'

import { prepareHeaders } from './prepareHeaders'

const { API_URL } = getConfig()

interface IFormatTextRequest {
  id: string
  text: string
  lastUpdated?: string
}
interface IFormatTextResponse {
  id: string
  text: string
  summary?: string
}

export const formatTextApi = createApi({
  reducerPath: 'educations-api',
  baseQuery: fetchBaseQuery({
    timeout: 60000,
    baseUrl: `${API_URL}kompetens/prognosportal/formattext`,
    prepareHeaders,
  }),
  refetchOnMountOrArgChange: true,
  tagTypes: [],

  endpoints: (build) => ({
    postFormatText: build.mutation<IFormatTextResponse, IFormatTextRequest>({
      query: (body) => {
        return {
          url: `/`,
          method: 'POST',
          body,
        }
      },
    }),
  }),
})

export const { usePostFormatTextMutation } = formatTextApi
