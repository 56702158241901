import { Box } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { RouteComponentProps } from 'react-router-dom'
import { useEpiContent } from '@trr/app-shell-data'

import { Content } from '../../App/App.types'
import OccupationsList from '../Yrke/components/OccupationsList/OccupationsList'
import PageWrapper from '../../Components/PageWrapper/PageWrapper'
import { decodeUrl } from '../../utils/Helpers/codeUri'
import { useGetEducationQuery } from '../../utils/Network/educationsApi'
import { useMatchByEducationIdQuery } from '../../utils/Network/occupationApi'
import EmptyResult from '../../Components/EmptyResult/EmptyResult'
import DelayedSkeleton from '../../Components/DelayedSkeleton/DelayedSkeleton'

import EducationDetails from './components/EducationDetails/EducationDetails'

interface IMatchParams {
  id: string
}
interface IUtbildningProps extends RouteComponentProps<IMatchParams> {
  id: string
}

const Utbildning: React.FC<IUtbildningProps> = ({ match }) => {
  const {
    utbildning: { errorFetchUtb, tomtResultat, tomtResultatYrken, titleYrken },
  } = useEpiContent<Content>()

  const [hasFetchedOccupations, setHasFetchedOccupations] = useState(false)
  const decodedUri = decodeUrl(match.params.id)
  const {
    data: educationData,
    isLoading,
    isError,
  } = useGetEducationQuery(decodedUri)
  const { data: occupationData, isSuccess } =
    useMatchByEducationIdQuery(decodedUri)
  useEffect(() => {
    if (isSuccess) setHasFetchedOccupations(true)
  }, [isSuccess])

  if (!educationData)
    return (
      <DelayedSkeleton
        sx={{ mb: 3 }}
        variant="rectangular"
        width={'100%'}
        height={477}
      />
    )

  return (
    <>
      <PageWrapper
        isLoading={isLoading}
        errorText={errorFetchUtb}
        errorLoadingData={isError}
      >
        <Box
          sx={{ p: { xs: 2, sm: 5 }, mb: 3 }}
          bgcolor="rgba(242, 237, 248, 1)"
        >
          <EducationDetails
            headerComponent="h1"
            education={educationData}
            isSummary={false}
          />
        </Box>

        {hasFetchedOccupations && (
          <OccupationsList
            itemHeaderComponent="h3"
            noResult={
              <EmptyResult
                heading={tomtResultat}
                description={tomtResultatYrken}
              />
            }
            header={titleYrken}
            occupations={occupationData?.occupations ?? []}
          />
        )}
      </PageWrapper>
    </>
  )
}

export default Utbildning
