/* eslint-disable @typescript-eslint/restrict-template-expressions */
import { DownloadForOffline } from '@mui/icons-material'
import {
  Button,
  Card,
  CardActions,
  CardContent,
  Typography,
  Box,
  useTheme,
} from '@mui/material'
import DescriptionIcon from '@mui/icons-material/Description'
import React from 'react'
import { format, parseISO } from 'date-fns'
import { Link } from 'react-router-dom'
import { useEpiContent } from '@trr/app-shell-data'

import { Content } from '../../App/App.types'
import useDeviceSize from '../../utils/Hooks/useDeviceSize'
import downloadBlobHelper from '../../utils/Helpers/downloadBlobHelper'

import { IIndustryArticle } from './types/IndustryArticle.type'

interface IArticleCardProps {
  values?: IIndustryArticle
}

const ArticleCard: React.FC<IArticleCardProps> = ({ values }) => {
  const {
    rapportkort: { fran, link, laddaNer },
  } = useEpiContent<Content>()
  const { palette } = useTheme()
  const { mediumScreensUp } = useDeviceSize()
  const publicationDate = values?.publicationDate
    ? format(parseISO(values?.publicationDate as string), 'yyyy-MM-dd')
    : ''
  const type = 'Rapport'
  const handleClickDownload = async () => {
    if (values?.id)
      await downloadBlobHelper(values?.id, values?.fileName ?? values.title)
  }

  const ariaLabel = `${link} ${values?.title}`
  const ariaLabel2 = `${laddaNer} ${values?.title}`
  return (
    <Card
      component="article"
      sx={{
        flexGrow: 1,

        display: 'flex',
        justifyContent: 'space-between',
        flexDirection: 'row',
      }}
    >
      <Box>
        <CardContent sx={{ p: 3 }}>
          <Typography
            color={palette.text.secondary}
            variant="subtitle2"
            component="p"
          >
            {type} {fran} {values?.publisher} | {publicationDate}
          </Typography>
          <Typography gutterBottom variant="h5" component="h2">
            {values?.title}
          </Typography>
          <Typography variant="body2">{values?.description}</Typography>
        </CardContent>
        <CardActions sx={{ p: '12px' }}>
          <Button
            component={Link}
            to={`/rapporter/${values?.id ?? ''}`}
            variant="text"
            size="small"
            aria-label={ariaLabel}
          >
            {link}
          </Button>
          <Button
            startIcon={<DownloadForOffline />}
            variant="text"
            size="small"
            onClick={() => void handleClickDownload()}
            aria-label={ariaLabel2}
          >
            {laddaNer}
          </Button>
        </CardActions>
      </Box>
      {mediumScreensUp && (
        <Box
          sx={{
            p: 5,
            backgroundColor: 'surface.purple',
            flexDirection: 'column',
            justifyContent: 'center',
            display: 'flex',
          }}
        >
          <DescriptionIcon color="primary" sx={{ fontSize: '96px' }} />
        </Box>
      )}
    </Card>
  )
}

export default ArticleCard
