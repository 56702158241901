import { Box } from '@mui/material'
import React from 'react'

interface InputHeader {
  children: React.ReactNode
}

const InputHeader: React.FC<InputHeader> = ({ children }) => {
  return (
    <Box
      sx={{
        padding: 3,
        bgcolor: 'rgb(242, 237, 248)',
      }}
    >
      {children}
    </Box>
  )
}

export default InputHeader
