import { Tab, Tabs as MuiTabs, Typography, Alert, Box } from '@mui/material'
import React from 'react'
import { Link, Route, useLocation, Redirect } from 'react-router-dom'
import { useEpiContent, useIsFeatureEnabled } from '@trr/app-shell-data'

import { Content } from '../../App/App.types'
import { IRoute } from '../../types/IRoute.types'
import { useTrackVirtualView } from '@trr/gtm-tracking'

interface ITabsProps {
  routes: IRoute[]
}
const Tabs: React.FC<ITabsProps> = ({ routes }) => {
  const {
    innehall: { heading, preamble },
  } = useEpiContent<Content>()
  const displayOccupationalOutlook = useIsFeatureEnabled(
    'Prognosportalen-frontend_occupationOutlook_perm_240619'
  )
  const { pathname } = useLocation()

  const industryReportsFlag = useIsFeatureEnabled(
    'Prognosportalen-frontend_industryReports_perm_240619'
  )
  if (!industryReportsFlag)
    routes = routes.filter((r) => r.href !== 'rapporter')

  const currentRoute = routes.find((r) => pathname.includes(r.href))
  const trackVirtualPageView = useTrackVirtualView('Prognosportalen')

  return (
    <>
      {!displayOccupationalOutlook && (
        <Box mb={4}>
          <Alert severity="warning">
            Vi håller på att förbättra tjänsten. Under tiden kan vi inte visa
            yrkesprognoser. Tack för din förståelse.
          </Alert>
        </Box>
      )}
      {pathname === '/' && <Redirect to="/utbildningar" />}
      <Typography
        gutterBottom
        variant="h1"
        sx={{ wordBreak: 'break-word', overflowWrap: 'break-word' }}
      >
        {heading}
      </Typography>
      <Typography variant="body1">{preamble}</Typography>
      <MuiTabs
        sx={{ mb: 3, mt: 5 }}
        value={currentRoute?.href}
        variant="scrollable"
        scrollButtons="auto"
        allowScrollButtonsMobile
      >
        {routes.map((r) => (
          <Tab
            key={r.href}
            label={r.label}
            value={r.href}
            to={'/' + r.href}
            component={Link}
            onClick={() => trackVirtualPageView(r.label)}
          />
        ))}
      </MuiTabs>
      {routes.map((r) => (
        <Route key={r.href} path={`/${r.href}`}>
          {r.component}
        </Route>
      ))}
    </>
  )
}

export default Tabs
