import {
  Button,
  Checkbox,
  Divider,
  FormControlLabel,
  FormGroup,
  Typography,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Radio,
  RadioGroup,
} from '@mui/material'
import { useFormik } from 'formik'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useEpiContent } from '@trr/app-shell-data'

import { Content } from '../../../../App/App.types'
import { IApplicationState } from '../../../../utils/Store/configureStore'
import {
  IEducationFilters,
  updateFilters,
} from '../../../../utils/Store/educationFilterSlice'

import { mapToDetailedFilterObject, other, studyPaces } from './helpers'
import {
  useGetEducationFormsQuery,
  useGetEducationTypesQuery,
} from 'src/utils/Network/educationParametersApi'

interface IEducationFilterModalProps {
  open: boolean
  onCloseClick: () => void
}

const EducationFilterModal: React.FC<IEducationFilterModalProps> = ({
  open,
  onCloseClick,
}) => {
  const { data: educationFormData, isLoading: isLoadingEducationFormData } =
    useGetEducationFormsQuery()
  const { data: educationTypeData, isLoading: isloadingEducationTypeData } =
    useGetEducationTypesQuery()
  const isLoading: boolean =
    isLoadingEducationFormData || isloadingEducationTypeData

  const {
    utbildningar: { filter, form, visaEndastUtbildningar, takt, avbryt, klar },
  } = useEpiContent<Content>()
  const filters = useSelector<IApplicationState, IEducationFilters>(
    (state) => state.educationFilter.filters
  )
  const initialValues = {
    other: filters.other?.map((f) => f.id),
    educationForms: filters.educationForms?.map((f) => f.id),
    educationTypes: filters.educationTypes?.map((f) => f.id),
    studyPace: filters.studyPace?.id,
  }

  const dispatch = useDispatch()
  const { handleSubmit, handleChange, values, resetForm } = useFormik({
    initialValues: initialValues,
    onReset: () => {
      dispatch(
        updateFilters(
          mapToDetailedFilterObject(
            initialValues,
            educationFormData,
            educationTypeData
          )
        )
      )
    },
    onSubmit: (values) => {
      dispatch(
        updateFilters(
          mapToDetailedFilterObject(
            values,
            educationFormData,
            educationTypeData
          )
        )
      )
    },
  })

  if (isLoading) return null
  return (
    <Dialog
      open={open}
      onClose={onCloseClick}
      scroll="paper"
      fullWidth
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <DialogTitle>{filter}</DialogTitle>
      <DialogContent dividers>
        <form onSubmit={handleSubmit}>
          {educationFormData && (
            <>
              <Typography variant="subtitle1" component="h3">
                {form}
              </Typography>
              <FormGroup>
                {educationFormData.map((e) => (
                  <FormControlLabel
                    key={e.id}
                    name="educationForms"
                    value={e.id}
                    control={
                      <Checkbox
                        checked={values.educationForms?.includes(e.id)}
                      />
                    }
                    label={e.name}
                    onChange={handleChange}
                  />
                ))}
              </FormGroup>
            </>
          )}
          <Divider sx={{ marginBottom: 2, marginTop: 3 }} />
          <Typography variant="subtitle1" component="h3">
            {visaEndastUtbildningar}
          </Typography>
          <FormGroup>
            {other.map((o) => (
              <FormControlLabel
                key={o.id}
                name="other"
                value={o.id}
                control={<Checkbox checked={values.other?.includes(o.id)} />}
                label={o.name}
                onChange={handleChange}
              />
            ))}
          </FormGroup>
          <Divider sx={{ marginBottom: 2, marginTop: 3 }} />
          <Typography variant="subtitle1" component="h3">
            {takt}
          </Typography>
          <RadioGroup name="studyPace">
            {studyPaces.map((p) => (
              <FormControlLabel
                key={p.id}
                value={p.id}
                name="studyPace"
                control={<Radio checked={values.studyPace?.includes(p.id)} />}
                label={p.name}
                onChange={handleChange}
              />
            ))}
          </RadioGroup>
        </form>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => {
            resetForm()
            onCloseClick()
          }}
          variant="text"
        >
          {avbryt}
        </Button>
        <Button
          onClick={() => {
            handleSubmit()
            onCloseClick()
          }}
        >
          {klar}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default EducationFilterModal
