import { Box, Stack, Typography } from '@mui/material'
import React from 'react'

interface IIconTextProps {
  icon: JSX.Element
  label?: string
  subLabel?: string
  disabled?: boolean
}

export const IconText: React.FC<IIconTextProps> = ({
  icon,
  label,
  subLabel,
  disabled,
}) => {
  if (!label) return null
  return (
    <Stack
      data-testid="icon-text-wrapper"
      sx={{
        mt: {
          sm: 2,
          xs: 1,
        },
        mb: {
          sm: 2,
          xs: 1,
        },
        mr: {
          sm: 4,
          xs: 0,
        },
        opacity: disabled ? '.6' : '1',
        width: {
          sm: '250px',
        },
      }}
      alignItems="center"
      spacing={1}
      direction="row"
    >
      {icon}
      <Box>
        <Typography variant="subtitle1" component="p">
          {label}
        </Typography>
        <Typography variant="subtitle2" component="p">
          {subLabel}
        </Typography>
      </Box>
    </Stack>
  )
}
