import { Button, ButtonGroup, capitalize } from '@mui/material'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  addEducationTypeFilter,
  removeEducationTypeFilter,
} from 'src/api/slices/educationFilterSlice'

import { ApplicationState } from 'src/store'

const EducationTypeFilterButtons = () => {
  const educationTypFilters = useSelector(
    (state: ApplicationState) => state.educationFilter.filters.educationTypes
  )
  const onEducationTypeFilterClick = (clickedFilter: {
    name: string
    active: boolean
  }) => {
    if (!clickedFilter.active)
      dispatch(
        addEducationTypeFilter({
          name: clickedFilter.name,
          id: clickedFilter.name.toLowerCase(),
        })
      )
    else if (clickedFilter.active) {
      dispatch(
        removeEducationTypeFilter({
          name: clickedFilter.name,
          id: clickedFilter.name.toLowerCase(),
        })
      )
    }
  }

  const dispatch = useDispatch()

  return (
    <ButtonGroup sx={{ mt: 3 }}>
      {[{ name: 'Program' }, { name: 'Kurs' }, { name: 'Kurspaket' }].map(
        (filter) => {
          const active = educationTypFilters?.find(
            (f) => f.name === filter.name
          )
          return (
            <Button
              key={filter.name}
              onClick={() =>
                onEducationTypeFilterClick({
                  name: filter.name,
                  active: !!active,
                })
              }
              variant={active ? 'contained' : 'outlined'}
              aria-pressed={!!active}
            >
              {capitalize(filter.name ?? '')}
            </Button>
          )
        }
      )}
    </ButtonGroup>
  )
}

export default EducationTypeFilterButtons
