/* eslint-disable import/no-extraneous-dependencies */
import { Box, Button, Collapse, TextField, Typography } from '@mui/material'
import React, { useEffect } from 'react'
import { TransitionGroup } from 'react-transition-group'
import { useDispatch, useSelector } from 'react-redux'
import { useFormik } from 'formik'
import { Content } from 'src/App/App.types'
import { useEpiContent } from '@trr/app-shell-data'

import PageWrapper from '../../Components/PageWrapper/PageWrapper'
import {
  updateOccupationSearch,
  useGetJobTitlesQuery,
} from '../../utils/Network/occupationApi'
import { IApplicationState } from '../../utils/Store/configureStore'
import ReliabilityBlock from '../../Components/ReliabilityBlock/ReliabilityBlock'
import InputHeader from '../../Components/InputHeader/InputHeader'
import { IJobTitle } from '../../types/IJobTitle.types'
import OccupationCard from '../Yrke/components/OccupationCard/OccupationCard'
import EmptyResult from '../../Components/EmptyResult/EmptyResult'

import YrkesrollerStart from './YrkesrollerStart'
import { useTrackCustomClick } from '@trr/gtm-tracking'

interface FormValues {
  search: string
}

const Yrkesroller = () => {
  const dispatch = useDispatch()
  const trackCustomClick = useTrackCustomClick()

  const occupationSearch = useSelector<IApplicationState, string>(
    (state) => state.occupation.form.occupationSearch
  )

  const {
    data = {} as IJobTitle[],
    isError,
    isSuccess,
    isFetching,
  } = useGetJobTitlesQuery(occupationSearch, {
    skip: !occupationSearch,
  })

  useEffect(() => {
    if (isFetching) {
      trackCustomClick('Sökning', {
        label: `Yrkessökning gjordes`,
      })
    }
  }, [isFetching, trackCustomClick])

  const { handleSubmit, handleChange, values } = useFormik({
    initialValues: {
      search: occupationSearch,
    },
    onSubmit: (values: FormValues) => {
      dispatch(updateOccupationSearch(values.search))
    },
  })
  const {
    yrken: {
      sokYrke,
      sok,
      errorFetchYrke,
      visar,
      yrken,
      yrke,
      tomtResultat,
      tomtResultat2,
    },
  } = useEpiContent<Content>()

  return (
    <>
      <InputHeader>
        <form onSubmit={handleSubmit}>
          <Box sx={{ display: 'flex', gap: 2 }}>
            <TextField
              sx={{ backgroundColor: 'white', width: '100%' }}
              label={sokYrke}
              id="search"
              name="search"
              type="search"
              value={values.search}
              onChange={handleChange}
            />
            <Button
              type="submit"
              sx={{ marginLeft: 'auto' }}
              size="medium"
              variant="contained"
            >
              {sok}
            </Button>
          </Box>
        </form>
      </InputHeader>

      <PageWrapper errorText={errorFetchYrke} errorLoadingData={isError}>
        {data?.length > 0 ? (
          <>
            <Typography sx={{ mt: 1 }} variant="body2">
              {visar} <b>{data.length}</b> {data.length > 1 ? yrken : yrke}
            </Typography>
            <TransitionGroup>
              {data.map((o) => (
                <Collapse key={o.occupationId}>
                  <OccupationCard
                    id={o.occupationId}
                    ssyk={o.occupationGroup.ssyk}
                    header={o.occupationLabel}
                    subheader={o.occupationGroup.occupationGroupLabel}
                    headerComponent="h2"
                    occupationGroupId={o.occupationGroup.conceptTaxonomyId}
                    occupationGroupLabel={
                      o.occupationGroup.occupationGroupLabel
                    }
                  />
                </Collapse>
              ))}
            </TransitionGroup>
          </>
        ) : (
          <Box sx={{ mt: 4 }}>
            {isSuccess ? (
              <EmptyResult heading={tomtResultat} description={tomtResultat2} />
            ) : (
              <YrkesrollerStart />
            )}
          </Box>
        )}
      </PageWrapper>

      <ReliabilityBlock />
    </>
  )
}

export default Yrkesroller
