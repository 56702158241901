import { Box, Button, Chip } from '@mui/material'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { IApplicationState } from '../../utils/Store/configureStore'
import {
  deleteAllSelectedCompetences,
  deleteSelectedCompetence,
} from '../../utils/Network/competenceApi'

const SkillsFilterChips = () => {
  const skills = useSelector(
    (state: IApplicationState) => state.competence.selectedCompetences
  )
  const dispatch = useDispatch()
  return (
    <Box
      sx={{
        mt: 2,
        display: 'flex',
        gap: 1,
        flexWrap: 'wrap',
      }}
    >
      {skills.length > 0 && (
        <Button
          onClick={() => dispatch(deleteAllSelectedCompetences())}
          size="small"
        >
          Rensa
        </Button>
      )}
      {skills.map((competence) => {
        if (!competence) return null
        return (
          <Chip
            key={competence?.term}
            color="primary"
            variant="outlined"
            onDelete={() => {
              dispatch(deleteSelectedCompetence(competence))
            }}
            label={competence.term}
          />
        )
      })}
    </Box>
  )
}

export default SkillsFilterChips
