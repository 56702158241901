import { Box, Stack, useMediaQuery, useTheme } from '@mui/material'
import React from 'react'
import WysiwygEditor from 'src/components/WysiwygEditor'
import { useEpiContent } from '@trr/app-shell-data'
import EpiImage from 'src/components/EpiImage/EpiImage'

// eslint-disable-next-line @typescript-eslint/consistent-type-definitions
interface YrkenContent {
  [key: string]: unknown
  yrken: {
    puffText: {
      mainBody: string
    }
    puffBild: {
      url: string
    }
  }
}

const YrkenStart = () => {
  const theme = useTheme()
  const {
    yrken: { puffText, puffBild },
  } = useEpiContent<YrkenContent>()

  return (
    <Stack
      data-testid="yrke-start"
      direction="row"
      sx={{ mt: 4, backgroundColor: 'surface.orange' }}
    >
      <Box sx={{ padding: { sm: 5, xs: 2 }, paddingTop: { sm: 0 } }}>
        {puffText?.mainBody && (
          <WysiwygEditor
            htmlFormattedString={puffText?.mainBody}
            styles={{
              fontSize: '16px',
              pt: 1,
              pb: 3,
            }}
          />
        )}
      </Box>
      {useMediaQuery(theme.breakpoints.up('md')) && puffBild && (
        <EpiImage width={'400px'} url={puffBild.url} />
      )}
    </Stack>
  )
}

export default YrkenStart
