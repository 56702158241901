import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { equals, reject } from 'ramda'

import { IFilter } from '../../types/IFilter.types'
import { Location } from 'src/types/Location'

export interface IEducationFilters {
  educationForms?: IFilter[]
  educationTypes?: IFilter[]
  studyPace?: IFilter
  other?: IFilter[]
}

export interface IEducationFilterState {
  lastUpdated: number
  page: number
  filters: IEducationFilters
  educationSearch: string
  location: Location
}

const initialState: IEducationFilterState = {
  lastUpdated: 0,
  page: 0,
  educationSearch: '',
  location: {
    id: '',
    name: '',
  },
  filters: {
    educationForms: [] as IFilter[],
    educationTypes: [{ name: 'Program', id: 'program' }] as IFilter[],
    studyPace: {} as IFilter,
    other: [] as IFilter[],
  },
}

const removeDeep = (filters: IEducationFilters, filter: IFilter) => {
  const newFilters = { ...filters }

  newFilters.educationForms = newFilters?.educationForms?.filter(
    (e) => !equals(filter, e)
  )
  newFilters.educationTypes = newFilters?.educationTypes?.filter(
    (e) => !equals(filter, e)
  )
  newFilters.other = newFilters?.other?.filter((e) => !equals(filter, e))
  newFilters.studyPace = {} as IFilter

  return newFilters
}

export const educationFilterSlice = createSlice({
  name: 'educationFilter',
  initialState,
  reducers: {
    updateFilters: (state, action: PayloadAction<IEducationFilters>) => {
      state.lastUpdated = new Date().getTime()
      state.filters = { ...state.filters, ...action.payload }
      state.page = 0
    },
    deleteFilter: (state, action: PayloadAction<IFilter>) => {
      state.lastUpdated = new Date().getTime()
      state.filters = { ...removeDeep(state.filters, action.payload) }
      state.page = 0
    },
    deleteAllFilters: (state) => {
      state.lastUpdated = new Date().getTime()
      state.filters = initialState.filters
      state.page = 0
    },
    updatePage: (state, action: PayloadAction<number>) => {
      state.page = action.payload
    },
    updateLocation: (state, action: PayloadAction<Location>) => {
      state.location = action.payload
      state.page = 0
    },
    updateEducationSearch: (state, action: PayloadAction<string>) => {
      state.educationSearch = action.payload
      state.page = 0
    },
    addEducationTypeFilter: (state, action: PayloadAction<IFilter>) => {
      state.filters.educationTypes = state.filters.educationTypes
        ? [...state.filters.educationTypes, action.payload]
        : []
    },
    removeEducationTypeFilter: (state, action: PayloadAction<IFilter>) => {
      const newArr = state.filters.educationTypes
        ? [...state.filters.educationTypes]
        : []
      state.filters.educationTypes = reject(
        (r: IFilter) => r.name === action.payload.name,
        newArr
      )
    },
  },
})

export const {
  updateFilters,
  deleteFilter,
  deleteAllFilters,
  updatePage,
  updateLocation,
  updateEducationSearch,
  addEducationTypeFilter,
  removeEducationTypeFilter,
} = educationFilterSlice.actions
