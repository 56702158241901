import { FilterList } from '@mui/icons-material'
import { Box, Button, TextField } from '@mui/material'
import { useFormik } from 'formik'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'

import EducationsList from './components/EducationsList/EducationsList'
import EducationFilterModal from './components/EducationFilterModal/EducationFilterModal'
import EducationFilterChips from './components/EducationFilterChipStack/EducationFilterChips'
import UtbildningarStart from './UtbildningarStart'
import EducationTypeFilterButtons from './components/EducationTypeFilterButtons/EducationTypeFilterButtons'
import { Location, LocationType } from 'src/types/Location'
import { useTrackCustomClick } from '@trr/gtm-tracking'
import { ApplicationState } from 'src/store'
import { useEducationsSearchQuery } from 'src/api/educations'
import {
  updateEducationSearch,
  updateLocation,
} from 'src/api/slices/educationFilterSlice'
import EmptyResult from 'src/components/EmptyResult/EmptyResult'
import InputHeader from 'src/components/InputHeader/InputHeader'
import LocationInput from 'src/components/LocationInput/LocationInput'
import ReliabilityBlock from 'src/components/ReliabilityBlock/ReliabilityBlock'

interface FormValues {
  education: string
  location: Location
}

const Utbildningar = () => {
  const [filterModalOpen, setFilterModalOpen] = useState(false)
  const dispatch = useDispatch()
  const trackCustomClick = useTrackCustomClick()
  const { filters, location, educationSearch } = useSelector(
    (state: ApplicationState) => state.educationFilter
  )
  const { t } = useTranslation('translation', { keyPrefix: 'utbildningar' })

  const isDistance = !!filters?.other?.find((f) => f.id === 'distans')
  const educationType = filters?.educationTypes
    ? filters.educationTypes.map((f) => f.id)
    : null
  const educationForm = filters?.educationForms
    ? filters.educationForms.map((f) => f.id)
    : null
  const pacePercentage = filters?.studyPace?.id

  const { data, isSuccess, isFetching } = useEducationsSearchQuery(
    {
      query: educationSearch,
      offset: 0,
      limit: 100,
      municipalityCode:
        location.type === LocationType.municipality ? location.id : null,
      regionCode: location.type === LocationType.county ? location.id : null,
      isDistance,
      pacePercentage,
      educationType,
      educationForm,
    },
    { skip: !educationSearch && !location.id }
  )
  const { setFieldValue, handleSubmit, handleChange, values } = useFormik({
    initialValues: {
      education: educationSearch,
      location,
    },
    onSubmit: (values: FormValues) => {
      dispatch(updateEducationSearch(values.education))
      values?.location?.id
        ? dispatch(updateLocation(values.location))
        : dispatch(
            updateLocation({
              id: '',
              name: '',
            })
          )
    },
  })

  useEffect(() => {
    if (isFetching) {
      trackCustomClick('Sökning', {
        label: `Utbildningssökning gjordes`,
      })
    }
  }, [isFetching, trackCustomClick])

  return (
    <>
      <InputHeader>
        <form onSubmit={handleSubmit}>
          <Box
            sx={{
              display: 'flex',
              gap: 2,
              flexDirection: {
                xs: 'column',
                sm: 'row',
              },
            }}
          >
            <TextField
              sx={{ backgroundColor: 'white' }}
              fullWidth
              id="education"
              name="education"
              type="search"
              value={values.education}
              label={t('sokUtb')}
              onChange={handleChange}
            />
            <LocationInput
              value={values.location ?? null}
              defaultValue={location}
              label={t('plats')}
              onLocationSelect={(v) => void setFieldValue('location', v)}
            />
            <Button
              data-testid="search-button"
              sx={{ marginLeft: { sm: 'auto', xs: 'unset' } }}
              type="submit"
              size="medium"
              variant="contained"
            >
              {t('sok')}
            </Button>
          </Box>
        </form>
        <Box
          sx={{
            mt: 2,
            display: 'flex',
            gap: 1,
            flexWrap: 'wrap',
          }}
        >
          <EducationFilterChips />
        </Box>
        <Box
          sx={{
            marginTop: 2,
            display: 'flex',
            gap: 1,
            flexWrap: 'wrap',
          }}
        >
          <Button
            data-testid="filter-button"
            onClick={() => setFilterModalOpen(true)}
            startIcon={<FilterList />}
            size="small"
          >
            {t('filter')}
          </Button>
        </Box>
      </InputHeader>

      {isSuccess && <EducationTypeFilterButtons />}
      {isSuccess ? (
        <EducationsList
          itemHeaderComponent="h2"
          showTypeToggle
          noResult={
            <EmptyResult
              heading={t('tomtResultat')}
              description={t('tomtResultatUtb')}
            />
          }
          educations={data?.educations ?? []}
        />
      ) : (
        <UtbildningarStart />
      )}
      <ReliabilityBlock />
      {filterModalOpen && (
        <EducationFilterModal
          open={filterModalOpen}
          onCloseClick={() => setFilterModalOpen(false)}
        />
      )}
    </>
  )
}

export default Utbildningar
