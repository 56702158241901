import { Button, Chip } from '@mui/material'
import * as R from 'ramda'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useEpiContent } from '@trr/app-shell-data'

import { Content } from '../../../../App/App.types'
import { IApplicationState } from '../../../../utils/Store/configureStore'
import {
  deleteAllFilters,
  deleteFilter,
} from '../../../../utils/Store/educationFilterSlice'
import { IFilter } from '../../../../types/IFilter.types'
import { useGetEducationTypesQuery } from 'src/utils/Network/educationParametersApi'

const filterList = (mainList: IFilter[], filterList: IFilter[]): IFilter[] => {
  const flatFilters = R.flatten(R.values(mainList)) as IFilter[]

  // Flatten array
  const filterIds = R.pluck('id', filterList)
  const filterPredicate = R.complement(
    R.propSatisfies(R.includes(R.__, filterIds), 'id')
  )
  let filteredList = R.filter(filterPredicate, flatFilters)

  // Filter out empty objects
  const isNotEmptyObject = (obj: IFilter) => !R.isEmpty(obj)
  filteredList = R.filter(isNotEmptyObject, filteredList)

  return filteredList
}

const EducationFilterChips = () => {
  const dispatch = useDispatch()
  const filters = useSelector(
    (state: IApplicationState) => state.educationFilter.filters
  )
  const {
    utbildningar: { rensa },
  } = useEpiContent<Content>()
  const { isLoading: isloadingEducationTypeData } = useGetEducationTypesQuery()
  const educationTypes = useSelector(
    (state: IApplicationState) => state.educationParameters.educationTypes
  )

  // Convert filters (of type IEducationFilters) to a flat array
  const filteredList = filterList(filters as IFilter[], educationTypes)

  if (isloadingEducationTypeData) return null

  return (
    <>
      {filteredList.length > 0 && (
        <Button onClick={() => dispatch(deleteAllFilters())} size="small">
          {rensa}
        </Button>
      )}
      {filteredList.map((f) => {
        if (f) {
          return (
            <Chip
              key={f.id}
              color="primary"
              variant="outlined"
              onDelete={() => {
                dispatch(deleteFilter(f))
              }}
              label={f.name}
            />
          )
        }
      })}
    </>
  )
}

export default EducationFilterChips
