import { Box, Typography, Stack, useMediaQuery, useTheme } from '@mui/material'
import React from 'react'
import WysiwygEditor from 'src/Components/WysiwygEditor'
import { useEpiContent } from '@trr/app-shell-data'

import EpiImage from '../../Components/EpiImage/EpiImage'

// eslint-disable-next-line @typescript-eslint/consistent-type-definitions
type IYrkesrollerStartContent = {
  yrken: {
    header: string
  }
  bildmandrickerkaffe: {
    url: string
  }
  yrkeMainBody: {
    mainBody: string
  }
}

const YrkesrollerStart = () => {
  const {
    yrken: { header },
    bildmandrickerkaffe: { url },
    yrkeMainBody: { mainBody },
  } = useEpiContent<IYrkesrollerStartContent>()

  const theme = useTheme()
  return (
    <Stack direction="row" sx={{ mt: 4, backgroundColor: 'surface.orange' }}>
      <Box sx={{ padding: { xs: 2, sm: 5 } }}>
        <Typography
          gutterBottom
          variant="h2"
          sx={{ wordBreak: 'break-word', overflowWrap: 'break-word' }}
        >
          {header}
        </Typography>
        {mainBody && (
          <WysiwygEditor
            htmlFormattedString={mainBody}
            styles={{
              fontSize: '16px',
              pt: 3,
              pb: 3,
            }}
          />
        )}
      </Box>
      {useMediaQuery(theme.breakpoints.up('md')) && url && (
        <EpiImage width={'400px'} url={url} />
      )}
    </Stack>
  )
}

export default YrkesrollerStart
