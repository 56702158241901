export type Ancestor = {
  path: string
  label: string
}

export const getAncestorLinks = (pathname: string): Ancestor[] => {
  const arr = pathname.split('/')

  const ancestors: Ancestor[] = arr
    .filter((f) => f !== '')
    .map((a): Ancestor => {
      if (a === 'mitt-trr') {
        return {
          path: a,
          label: 'Mitt TRR',
        }
      }
      if (a === 'karriarvagledning') {
        return {
          path: a,
          label: 'Karriärvägledning',
        }
      }
      return {
        path: a,
        label: a,
      }
    })

  return ancestors
}
