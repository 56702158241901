import { Box, LinearProgress } from '@mui/material'
import { map, prop } from 'ramda'
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import KompetenserStart from './KompetenserStart'
import SkillsFilterChips from './SkillsFilterChips'
import { useTrackCustomClick } from '@trr/gtm-tracking'
import { useMatchByTextQuery } from 'src/api/occupation'
import { addSelectedCompetence } from 'src/api/competence/competence'
import { ApplicationState } from 'src/store'
import DelayedSkeleton from 'src/components/DelayedSkeleton/DelayedSkeleton'
import EmptyResult from 'src/components/EmptyResult/EmptyResult'
import InputHeader from 'src/components/InputHeader/InputHeader'
import ReliabilityBlock from 'src/components/ReliabilityBlock/ReliabilityBlock'
import SkillInput from 'src/components/SkillInput/SkillInput'
import OccupationsList from '../Yrke/components/OccupationsList/OccupationsList'
import { useTranslation } from 'react-i18next'
import { Competence } from 'src/types/Competence'

const Kompetenser = () => {
  const dispatch = useDispatch()
  const trackCustomClick = useTrackCustomClick()
  const { t } = useTranslation('translation', {
    keyPrefix: 'kompetenser',
  })

  const skills = useSelector(
    (state: ApplicationState) => state.competence.selectedCompetences
  )
  const skillsString = map(prop('term'), skills).join(', ')

  const { data, isSuccess, isFetching } = useMatchByTextQuery(skillsString, {
    skip: !skillsString || skills.length < 3,
  })
  useEffect(() => {
    if (isFetching) {
      trackCustomClick('Sökning', {
        label: `Kompetenssökning gjordes`,
      })
    }
  }, [isFetching, trackCustomClick])

  const onCompetenceSelect = (competence: Competence) => {
    if (competence) dispatch(addSelectedCompetence(competence))
  }

  return (
    <>
      <InputHeader>
        <Box sx={{ display: 'flex', gap: 2 }}>
          <SkillInput onCompetenceSelect={onCompetenceSelect} />
        </Box>
        <SkillsFilterChips />
      </InputHeader>
      {isFetching && (
        <LinearProgress
          sx={{ margin: 'auto', mt: 2, mb: -2, maxWidth: '300px' }}
        />
      )}
      {isSuccess && skills.length > 0 ? (
        <OccupationsList
          itemHeaderComponent="h2"
          occupations={data?.occupations ?? []}
          noResult={
            skills.length > 1 ? (
              isFetching ? (
                <DelayedSkeleton
                  sx={{ mb: 3 }}
                  variant="rectangular"
                  width={'100%'}
                  height={350}
                />
              ) : (
                <EmptyResult
                  heading={t('tomtResultat')}
                  description={t('tomtResultatKompetens')}
                />
              )
            ) : isFetching ? (
              <DelayedSkeleton
                sx={{ mb: 3 }}
                variant="rectangular"
                width={'100%'}
                height={350}
              />
            ) : (
              <EmptyResult
                heading={t('addMore')}
                description={t('addMoreBody')}
              />
            )
          }
        />
      ) : (
        <KompetenserStart />
      )}
      <ReliabilityBlock />
    </>
  )
}

export default Kompetenser
