import { Box, Stack, Typography, useTheme } from '@mui/material'
import React from 'react'
import type { OccupationStatistics } from '../../types/OccupationStatistics.types'
import { useTranslation } from 'react-i18next'

interface OccupationBarChartProps {
  outlook?: OccupationStatistics
}

const OccupationBarChart: React.FC<OccupationBarChartProps> = ({ outlook }) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'yrke',
  })
  const { palette } = useTheme()

  if (!outlook) return null

  const gender = [
    {
      label: t('men'),
      percent: Math.round((outlook.men / (outlook.men + outlook.women)) * 100),
      amount: outlook.men,
    },
    {
      label: t('women'),
      percent: Math.round(
        (outlook.women / (outlook.men + outlook.women)) * 100
      ),
      amount: outlook.women,
    },
  ].sort((a, b) => (a.amount ?? Infinity) - (b.amount ?? Infinity))

  const zeroEmployed = isNaN(gender[0].percent)

  return (
    <Stack
      role="figure"
      aria-labelledby="occupation-barchart-caption"
      sx={{
        backgroundColor: zeroEmployed
          ? palette.surface?.grey
          : palette.info.light,
        color: palette.info.dark,
        m: 0,
        mt: 2,
        p: 2,
      }}
      borderRadius={2}
    >
      <Typography
        sx={{
          mb: -1.6,
          color: zeroEmployed ? palette.text.secondary : palette.info.dark,
        }}
        variant="h2"
        component="p"
        gutterBottom={false}
      >
        {(outlook.men + outlook.women)?.toLocaleString('sv-SE')}
      </Typography>
      <Typography
        variant="body1"
        component="p"
        sx={{
          color: zeroEmployed ? palette.text.secondary : palette.info.dark,
        }}
      >
        {t('yrkesverksammaSverige')} {outlook.year}
      </Typography>
      <Stack direction="row" sx={{ height: '40px', mt: 2 }}>
        <Box
          sx={{
            backgroundColor: palette.info.dark,
            height: '100%',
            width: gender[0].percent && `${gender[0].percent}%`,
            borderTopLeftRadius: '8px',
            borderBottomLeftRadius: '8px',
          }}
        />
        <Box
          sx={{
            backgroundColor: zeroEmployed ? palette.grey[400] : 'white',
            height: '100%',
            flexGrow: '1',
            borderTopRightRadius: '8px',
            borderBottomRightRadius: '8px',
          }}
        />
      </Stack>

      {!zeroEmployed && (
        <Stack direction="row" justifyContent="space-between">
          <Box>
            <Typography variant="h5" component="p" gutterBottom={false}>
              {`${gender[0].percent}%`}
            </Typography>
            {gender[0].amount && (
              <Typography variant="body1" component="p">
                {gender[0].amount
                  ? `${gender[0].amount.toLocaleString('sv-SE')} ${gender[0].label}`
                  : ''}
              </Typography>
            )}
          </Box>
          <Box sx={{ textAlign: 'right' }}>
            <Typography variant="h5" component="p" gutterBottom={false}>
              {`${gender[1].percent}%`}
            </Typography>
            {gender[1].amount && (
              <Typography variant="body1" component="p">
                {gender[1].amount
                  ? `${gender[1].amount.toLocaleString('sv-SE')} ${gender[1].label}`
                  : ''}
              </Typography>
            )}
          </Box>
        </Stack>
      )}
    </Stack>
  )
}

export default OccupationBarChart
