import React from 'react'
import { Box, Stack, Typography } from '@mui/material'

import EmptyIllustration from './EmptyIllustration/EmptyIllustration'

interface IEmptyResultProps {
  heading: string
  description: string
}

const EmptyResult: React.FC<IEmptyResultProps> = ({ heading, description }) => {
  return (
    <Box
      sx={{
        justifyContent: 'space-between',
        flexDirection: 'row',
        display: 'flex',
        p: 5,
        backgroundColor: 'surface.orange',
      }}
    >
      <Stack sx={{ maxWidth: '600px' }}>
        <Typography gutterBottom variant="h3" component="h2">
          {heading}
        </Typography>
        <Typography variant="body1">{description}</Typography>
      </Stack>
      <Box sx={{ maxWidth: '170px' }}>
        <EmptyIllustration />
      </Box>
    </Box>
  )
}

export default EmptyResult
