import React, { useEffect } from 'react'
import { Typography } from '@mui/material'
import { useEpiContent } from '@trr/app-shell-data'

import { Content } from '../../../../App/App.types'
import { usePostFormatTextMutation } from '../../../../utils/Network/formatTextApi'
import { truncateText } from '../../../../utils/Helpers/truncateText'
import DelayedSkeleton from '../../../../Components/DelayedSkeleton/DelayedSkeleton'

interface IEducationDescriptionProps {
  text: string | undefined
  id: string | undefined
  lastEdited?: string
  showFormattedText: boolean
  isInView?: boolean
}

const EducationSummary: React.FC<IEducationDescriptionProps> = ({
  text,
  id,
  lastEdited,
  showFormattedText,
  isInView,
}) => {
  const [formatText, res] = usePostFormatTextMutation()
  const {
    utbildningskort: { noDesc },
  } = useEpiContent<Content>()
  useEffect(() => {
    if (
      !res.data &&
      !res.isLoading &&
      text &&
      id &&
      showFormattedText &&
      isInView
    )
      formatText({
        id,
        text,
        lastUpdated: lastEdited ?? undefined,
      }).catch((e) => console.log(e))
  }, [text, res, formatText, id, lastEdited, showFormattedText, isInView])

  const summary = showFormattedText
    ? res?.data?.summary
    : truncateText(text, 350, '..')

  if (res.isLoading && showFormattedText)
    return <DelayedSkeleton variant="rounded" width={'100%'} height={70} />
  if (!text && !res.isLoading)
    return <Typography variant="body1"> {noDesc}</Typography>
  if (summary)
    return (
      <Typography gutterBottom variant="subtitle2" component="p">
        {summary}
      </Typography>
    )
  return null
}

export default EducationSummary
