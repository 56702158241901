import { ArrowDropUp, ArrowDropDown, Close } from '@mui/icons-material'
import {
  Chip,
  Box,
  List,
  Typography,
  ListItem,
  ListItemButton,
  ListItemIcon,
  Checkbox,
  ListItemText,
  Button,
  Avatar,
} from '@mui/material'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import { CustomPopper } from 'src/components/CustomPopper/CustomPopper'
import { useTranslation } from 'react-i18next'

export interface Data {
  id: string
  label: string
}

export interface FilterType {
  id: string
  name: string
  label: string
  filters: string[]
  data?: Data[]
}

const Filter = ({
  filter,
  handleSetFilters,
  handleResetFilters,
}: {
  filter: FilterType
  handleSetFilters: (filterId: string, value: string) => void
  handleResetFilters: (filterId: string) => void
}) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'filtrering',
  })

  const [open, setOpen] = useState(false)

  const handleClose = () => {
    setOpen(false)
  }

  const handleOpen = () => {
    setOpen(true)
  }

  const ResetButton = () => (
    <Button
      data-testid="reset-filters-button"
      variant="text"
      onClick={() => {
        handleResetFilters(filter.id)
        setOpen(false)
      }}
      disabled={filter.filters.length === 0}
      sx={{ alignSelf: 'flex-end' }}
    >
      {t('rensa')}
    </Button>
  )

  const chipRef = useRef<HTMLInputElement>(null)

  useEffect(() => {
    if (open) {
      const chipBottom = chipRef.current?.offsetTop as number
      const popperHeight = 700
      const viewportBottom = window.scrollY + window.innerHeight
      const shouldScrollIntoView = chipBottom + popperHeight > viewportBottom
      shouldScrollIntoView &&
        chipRef.current?.scrollIntoView({ behavior: 'smooth' })
    }
  }, [open])

  const SelectCountIndicator = (
    <Avatar
      sx={{
        bgcolor: (theme) => theme.palette.secondary.main,
        color: '#fff !important',
      }}
    >
      {filter.filters.length}
    </Avatar>
  )

  const escFunction = useCallback((event: { key: string }) => {
    if (event.key === 'Escape') {
      // Close popper when user press escape
      setOpen(false)
    }
  }, [])

  useEffect(() => {
    document.addEventListener('keydown', escFunction, false)

    return () => {
      document.removeEventListener('keydown', escFunction, false)
    }
  }, [escFunction])

  return (
    <>
      <Chip
        label={
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              marginRight: '-7px',
            }}
            component={'span'}
          >
            {filter.label} {open ? <ArrowDropUp /> : <ArrowDropDown />}
          </Box>
        }
        aria-label={t('filtreraPa', {
          filterLabel: filter.label.toLowerCase(),
        })}
        onClick={handleOpen}
        ref={chipRef}
        sx={{ zIndex: open ? 2 : 'unset' }}
        avatar={filter.filters.length > 0 ? SelectCountIndicator : undefined}
        variant={open || filter.filters.length > 0 ? 'filled' : 'outlined'}
        data-testid="filter-chip"
      />

      <CustomPopper
        label={filter.label}
        headerIcon={<Close data-testid="close-filters-button" />}
        footer={<ResetButton />}
        open={open}
        anchor={chipRef.current as HTMLElement}
        onClickHeaderIcon={handleClose}
        onClickAway={handleClose}
      >
        <List sx={{ paddingBottom: '.5rem' }} disablePadding>
          {filter.data?.length === 0 && (
            <Typography variant="subtitle2" sx={{ pt: 1, pl: 1 }}>
              {t('noFilters')}
            </Typography>
          )}
          {filter.data?.map((option) => (
            <ListItem key={option.id} disablePadding divider={false}>
              <ListItemButton
                data-testid="filter-button"
                role="button"
                sx={{ paddingTop: 0, paddingBottom: 0, left: -5 }}
                onClick={() => handleSetFilters(filter.id, option.id)}
                dense
                tabIndex={-1}
              >
                <ListItemIcon sx={{ minWidth: 0 }}>
                  <Checkbox
                    edge="start"
                    checked={
                      filter.filters.some((filter) => filter === option.id) ??
                      false
                    }
                    disableRipple
                    name={option.label}
                    inputProps={{
                      'aria-label': option.label,
                    }}
                  />
                </ListItemIcon>
                <ListItemText id={option.id} primary={option.label} />
              </ListItemButton>
            </ListItem>
          ))}
        </List>
      </CustomPopper>
    </>
  )
}

export default Filter
